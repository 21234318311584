<template>
  <div
    :class="[
      'action-item',
      radio === true ? 'radio' : '',
      isSelected ? 'check' : '',
    ]"
    @click="select"
  >
    <div class="item-avatar-group">
      <div class="item-avatar">
        <img :src="getAvatarOrDummy(employee)" alt="" style="height: 50px" />
      </div>
      <h4>{{ employee.Vorname }} {{ employee.Nachname }}</h4>
    </div>
    <div>
      <button
        v-if="setRoles === true"
        :class="['button-menu', setRoles ? 'icon-star' : '']"
        @click="clickOnSetRoles"
      ></button>
      <button
        v-if="deleteEmployees === true"
        :class="['button-menu', 'icon-delete']"
        @click="clickOnDelete"
      ></button>
         <button
        v-if="removeAssistant === true"
        :class="['button-menu', 'icon-delete']"
        @click="clickOnRemoveAssistant"
      ></button>
    </div>
  </div>
</template>

<script>
import imageFunctionsMixin from "@/mixins/imageFunctions.js";

export default {
  name: "Employee",
  mixins: [imageFunctionsMixin],
  props: ["employee", "isSelected", "radio", "deleteEmployees", "setRoles", "removeAssistant"],
  data() {
    return {
      employees: [],
    };
  },
  methods: {
    clickOnRemoveAssistant(){
      this.$emit("removeAssistant", this.employee);

    },
    clickOnSetRoles() {
      this.$router.push("/employeeSetRoles/" + this.employee.uid);
    },

    select() {
      this.$emit("selected", this.employee);
    },
    clickOnDelete() {
      this.$emit("clickOnDelete", this.employee);
    },
  },
  mounted() {},
};
</script>