<template>
  <div>
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>

    <div id="page-login">
      <form class="login">
        <div class="header-login">
          <div class="login-title">Kundenportal</div>
          <div class="login-logo">
            <img src="@/assets/images/logo-ft_login.svg" alt="Florian Thoms" />
          </div>
        </div>
        <div class="login-form">
          <div class="form-field">
            <label for="email">Deine E-Mail</label>
            <input ref="email" name="email" type="email" v-model="email" />
          </div>

          <p style="color: red">{{ $store.state.loginErrorMsg }}</p>
        </div>
        <div class="login-footer">
          <button
            class="button-primary"
            @click.prevent="resetpw"
            :disabled="email.length === 0"
          >
            Passwort vergessen
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { auth, functions } from "@/plugins/firebase.js";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { httpsCallable } from "@firebase/functions";

import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

export default {
  name: "PasswordReset",
  mixins: [firestoreFunctionsMixin],
  components: {},
  data() {
    return {
      email: "",
      isLoading: false,
    };
  },
  methods: {
    async resetpw() {
      this.isLoading = true;
      const passwordReset = httpsCallable(functions, "passwordReset");
      const response = await passwordReset({
        email: this.email.trim(),
      });
      
      this.isLoading = false;
      if (response.data.data.code === 1) {
        this.$store.dispatch("showSnackbar", {
          text: `Wir haben dir eine E-Mail an ${this.email.trim()} geschickt. Bitte folge dem Link in der E-Mail um ein neues Passwort zu erstellen.`,
          color: "green",
        });
      } else if (response.data.data.code === 2) {
        this.$store.dispatch("showSnackbar", {
          text: `Dein Konto wurde noch nicht aktiviert. Bitte schau in deinen Mails nach der ersten Email um dein Passwort zu vergeben`,
          color: "green",
        });
      }
      this.$router.push({ name: "Login" });
    },
  },
  computed: {},
  mounted: async function () {
    if (this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "DBFrontend" });
    }
  },
};
</script>

<style>
</style>
      