const { getDownloadURL, ref, listAll } = require("@firebase/storage");
const { auth, firestore, storage } = require('@/plugins/firebase');
const { onLog } = require("@firebase/app");

module.exports.urltobase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}


module.exports.downloadAvatar = async (uid) => {
    const storageRef = ref(storage, `FlorianThoms/ProfilePictures/${uid}.jpg`);
    let that = this;

    try {
        let url = await getDownloadURL(storageRef);
        let b64 = await this.urltobase64(url);
        return b64;
    }
    catch (error) {
        return null;
    }

}

module.exports.getAllAvatars = async () => {
    const storageRef = ref(storage, `FlorianThoms/ProfilePictures`);
    let _result = [];
    let result = await listAll(storageRef);
    for (let imageRef of result.items) {
        let url = await getDownloadURL(imageRef);
        let b64 = await this.urltobase64(url);
        _result.push({
            filename: imageRef.name,
            b64: b64
        })
    }

    return _result;
}


module.exports.setAllAvatars = async (store) => {
    store.state.avatars = [];
    const storageRef = ref(storage, `FlorianThoms/ProfilePictures`);
    let _result = [];
    let result = await listAll(storageRef);



    result.items.forEach(async (imageRef) => {

        let name = imageRef.name;
        let url = await getDownloadURL(imageRef);
        let b64 = await this.urltobase64(url);

        store.state.avatars.push({ filename: name, image: b64 });
        _result.push({
            filename: imageRef.name,
            b64: b64
        })
    });



    return _result;
}