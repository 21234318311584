<template>
  <div >
    <a @click="itemClicked()" :class="['action-item', disable?'disabled':'']" >
      <div v-if="disable" class="tooltip">Wird freigeschaltet, wenn Zusage erfolgt</div>
      <div
        :class="[
          'item-title',
          zeigeFortschritt === true
            ? itemsCompleted.quantity == itemsCompleted.count
              ? 'complete'
              : 'progress'
            : '',
        ]"
      >
        <h4>{{ kategorie.title }}</h4>
        <div class="button-group">
          <div
            :class="[
              'tag',
              zeigeFortschritt === true
                ? itemsCompleted.quantity == itemsCompleted.count
                  ? 'complete'
                  : 'progress'
                : '',
            ]"
          >
            {{ itemsToComplete.erledigt }}/{{ itemsToComplete.count }}
          </div>
          
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "UnterlagenKategorieCustomer",
  props: ["kategorie", "id", "zeigeFortschritt", "disable"],
  data: function () {
    return {};
  },
  computed: {
    itemsToComplete() {
      let ret = {
        count: 0,
        erledigt: 0,
      };

      for (let i = 0; i < this.kategorie.ueberschriften.length; i++) {
        const ueberschrift = this.kategorie.ueberschriften[i];
        for (let a = 0; a < ueberschrift.items.length; a++) {
          const item = ueberschrift.items[a];
          if (item.toComplete) {
            ret.count++;
          }
          if (item.erledigt) {
            ret.erledigt++;
          }
        }
      }
      return ret;
    },

    itemsCompleted() {
      let quantity = 0;
      let count = 0;

      for (let i = 0; i < this.kategorie.ueberschriften.length; i++) {
        const ueberschrift = this.kategorie.ueberschriften[i];
        for (let a = 0; a < ueberschrift.items.length; a++) {
          const item = ueberschrift.items[a];
          if (item.erledigt && item.erledigt === true) {
            quantity++;
          }
          if (item.toComplete && item.toComplete === true) {
            count++;
          }
          
        }
      }
      return {
        quantity: quantity,
        count: count,
      };
    },
  },
  methods: {
    itemClicked() {
      this.$emit("itemClicked");
    },
    downloadDocuments() {
      console.log("trigger"); 
    },
  },
  mounted: async function () {},
};
</script>

<style scoped>

.v-application a .disabled {
    cursor: default!important;
}
</style>
