<template>
  <div>
    <div id="page-login">
      <div class="login-wrapper">
        <form class="login">
          <div class="header-login">
            <div class="login-title">Kundenportal</div>
            <div class="login-logo">
              <img
                src="@/assets/images/logo-ft_login.svg"
                alt="Florian Thoms"
              />
            </div>
          </div>
          <div class="login-form">
            <div class="form-field">
              <label for="email">Deine E-Mail</label>
              <input
                ref="email"
                name="email"
                type="email"
                v-on:keyup.enter="login"
                v-model="email"
              />
            </div>
            <div class="form-field">
              <label for="password">Passwort</label>
              <input
                ref="password"
                name="password"
                type="password"
                v-on:keyup.enter="login"
                v-model="password"
              />
            </div>
            <p style="color: red">{{ $store.state.loginErrorMsg }}</p>
          </div>
          <div class="login-footer">
            <input
              id="btn-login"
              class="button-primary"
              value="Anmelden"
              @click="login"
              ref="btnlogin"
            />
            <a class="reset-password" @click="gotoByName('PasswordReset')"
              >Passwort vergessen?</a
            >
          </div>
        </form>

        <div class="footer-legal">
          <a href="https://florian-thoms.de/impressum.html">Impressum</a>
          <a href="https://florian-thoms.de/datenschutz.html">Datenschutz</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase.js";
import { signInWithEmailAndPassword } from "@firebase/auth";
import routingFunctionsMixin from "@/mixins/routingFunctions.js";

import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

export default {
  name: "Login",
  mixins: [firestoreFunctionsMixin, routingFunctionsMixin],
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
    };
  },
  methods: {
    resetpw() {},
   
    async login() {
      try {
        this.$store.state.auth.authDone = false;

        try {
          await signInWithEmailAndPassword(auth, this.email, this.password);
          console.log("ok");
        } catch (error) {
          console.log("Error");
          console.log(error);
          if (error.message == "Firebase: Error (auth/user-disabled).") {
            this.$store.dispatch("showSnackbar", {
              text: `Dein Konto wurde noch nicht aktiviert. Bitte schau in deinen Mails nach der Email um ein initiales Passwort zu vergeben`,
              color: "red",
            });
            this.$store.state.auth.authDone = true;
          } else if (
            error.message === "Firebase: Error (auth/wrong-password)."
          ) {
            this.$store.dispatch("showSnackbar", {
              text: `Login fehlgeschlagen. Bitte überprüfe deine E-Mailadresse und dein Passwort`,
              color: "red",
            });

            this.$store.state.auth.authDone = true;
          } else {
            console.log(error);
            this.$store.dispatch("showSnackbar", {
              text: `Login fehlgeschlagen. Bitte überprüfe deine E-Mailadresse und dein Passwort`,
              color: "red",
            });
            //this.$store.state.loginErrorMsg = error.message;
            this.$store.state.auth.authDone = true;
          }
        }
      } catch (err) {
        this.error = err.message;
      }
    },
  },
  mounted: function () {
    if (this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "DBFrontend" });
    }

    let input = this.$refs.email;
    input.focus();
  },
};
</script>

<style>
</style>
      