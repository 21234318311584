<template></template>

<script>
import { auth } from "@/plugins/firebase.js";
import { signInWithEmailAndPassword } from "@firebase/auth";

import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

export default {
  name: "Login",
  mixins: [firestoreFunctionsMixin],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {},
  mounted: function () {
    this.$store.dispatch("userSignOut");
    this.$router.push({ name: "Login" });
  },
};
</script>

<style>
</style>
      