import Vue from 'vue'
import Router from 'vue-router'

import Login from '../views/Kundenportal/Login.vue'
import Logout from '../views/Kundenportal/Logout.vue'
import LoginBackend from '../views/Kundenportal/LoginBackend.vue'
import DashboardFrontend from '../views/Kundenportal/Frontend/Dashboard.vue'
import DashboardEmployee from '../views/Kundenportal/Backend/DashboardEmployee.vue'
import NeuerKunde from '../views/Kundenportal/Backend/NeuerKunde.vue'
import KategorienErzeugen from '../views/Kundenportal/Backend/KategorienErzeugen.vue'
import ProfileCustomer from '../views/Kundenportal/Backend/ProfileCustomer.vue'
import Profil from '../views/Kundenportal/Backend/Profil.vue'
import EmployeesOverview from '../views/Kundenportal/Backend/EmployeesOverview.vue'
import setPassword from '../views/Kundenportal/setPassword.vue'
import passwordReset from '../views/Kundenportal/passwordReset.vue'
import callback_onedrive from '../views/Kundenportal/callback_onedrive.vue'
import EmployeeSetRoles from '../views/Kundenportal/Backend/EmployeeSetRoles.vue'
import ManageCatgories from '../views/Kundenportal/Backend/ManageCatgories.vue'


Vue.use(Router)


const routes = [{
        path: '/',
        name: 'Default',
        component: Login,
        meta: {
            requiresAuth: false,
            showInDrawer: false,
            title: 'Login',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
            showInDrawer: false,
            title: 'Login',
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: false,
            showInDrawer: false,
            title: 'Logout',
        },
    },
    {
        path: '/loginbackend',
        name: 'LoginBackend',
        component: LoginBackend,
        meta: {
            requiresAuth: false,
            showInDrawer: false,
            title: 'Login',
        },
    },
    {
        path: '/dbfrontend',
        name: 'DBFrontend',
        component: DashboardFrontend,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Startseite',
        },
    },
    {
        path: '/dbbackend',
        name: 'DBBackend',
        component: DashboardEmployee,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Startseite',
        },
    },
    {
        path: '/newcustomer',
        name: 'NeuerKunde',
        component: NeuerKunde,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Kunde anlegen',
        },
    },
    {
        path: '/customerprofile/:uid',
        name: 'ProfilKunde',
        component: ProfileCustomer,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Kundenprofil',
        },
    },
    {
        path: '/profile',
        name: 'Profil',
        component: Profil,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Mein Profil',
        },
    },
    {
        path: '/employeesOverview',
        name: 'EmployeesOverview',
        component: EmployeesOverview,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Mitarbeiter Übersicht',
        },
    },
    {
        path: '/setPassword/:uid',
        name: 'SetPassword',
        component: setPassword,
        meta: { 
            requiresAuth: true,
            showInDrawer: false,
            title: 'Passwort vergeben',
        },
    },
    {
        path: '/passwordReset/',
        name: 'PasswordReset',
        component: passwordReset,
        meta: { 
            requiresAuth: true,
            showInDrawer: false,
            title: 'Passwort vergessen',
        },
    },
    {
        path: '/callback_onedrive/',
        name: 'CallbackOneDrive',
        component: callback_onedrive,
        meta: { 
            requiresAuth: true,
            showInDrawer: false,
            title: '',
        },
    },
    {
        path: '/employeeSetRoles/:uid',
        name: 'EmployeeSetRoles',
        component: EmployeeSetRoles,
        meta: { 
            requiresAuth: true,
            showInDrawer: false,
            title: '',
        },
    },
    {
        path: '/managecategories',
        name: 'Kategorien Verwalten',
        component: ManageCatgories,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Kategorien Verwalten',
        },
    },

    
        

    {
        path: '/test',
        name: 'KategorienErzeugen',
        component: KategorienErzeugen,
        meta: {
            requiresAuth: true,
            showInDrawer: false,
            title: 'Kategorien Erzeugen',
        },
    },

    { path: "*", component: Login }
];

export default new Router({
    routes: routes,
    mode: 'history',

})