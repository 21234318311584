<template>
  <div>
    <v-dialog v-model="visible" width="500" :persistent="persistent">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>
        <br />
        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="btnOk.color"
            text
            @click="
              btnOk.fnc();
              visible = false;
            "
            >{{ btnOk.text }}</v-btn
          >
          <v-btn
            :color="btnCancel.color"
            text
            @click="
              btnCancel.fnc();
              visible = false;
            "
            >{{ btnCancel.text }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "customDialog",
  mixins: [],
  props: [""],
  data() {
    return {
      visible: false,
      title: "Titel",
      message: "Body",
      btnOk: { color: "primary" },
      btnCancel: { color: "primary" },
      persistent: true,
    };
  },
  methods: {
    showDialog(title, message, btnOk, btnCancel, persistent) {
      this.visible = true;
      this.title = title;
      this.message = message;
      this.btnOk = btnOk;
      this.btnCancel = btnCancel;
      this.persistent = persistent;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>