<template>
  <v-app>
    <v-main>
      <v-snackbar
        id="application-snackbar"
        v-model="$store.state.snackbar.visible"
        :timeout="-1"
        :color="$store.state.snackbar.color"
      >
        <center>{{ $store.state.snackbar.text }}</center>
      </v-snackbar>

      <div
        v-if="isLoading"
        class="text-center"
        :key="this.$store.state.auth.loadingKey"
      >
        <loading
          :active="isLoading"
          :color="'#78AEF5'"
          :can-cancel="false"
          :is-full-page="true"
        />
      </div>
      <div v-else>
        <KPHeader v-if="$route.path != '/login' && $route.path != '/'" />
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import KPHeader from "@/components/Header.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  mixins: [],
  components: {
    KPHeader,
    Loading,
  },
  data: () => ({}),
  methods: {
    init() {
      console.log("Init");
    },
  },
  computed: {
    isLoading() {
      if (!this.$store.state.auth.authDone) {
        return true;
      }
      return false;
    },
  },
  async mounted() {},
};
</script>

<style>
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/static/Manrope-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/static/Manrope-Medium.ttf) format("truetype");
  font-weight: medium;
  font-style: normal;
}


</style>
