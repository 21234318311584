<template>
  <div>
    <a @click="itemClicked()" class="action-item ">
      <div :class="[
        'item-title',
        zeigeFortschritt === true
          ? itemsCompleted.quantity == itemsCompleted.count
            ? 'complete'
            : 'progress'
          : '',
      ]">
        <h4>{{ kategorie.title }}</h4>
        <div class="button-group">
          <div :class="[
            'tag',
            zeigeFortschritt === true
              ? itemsCompleted.quantity == itemsCompleted.count
                ? 'complete'
                : 'progress'
              : '',
          ]">
            {{ zeigeFortschritt === true ? itemsCompleted.quantity : itemsToComplete.quantity }}/{{ zeigeFortschritt ===
                true ? itemsCompleted.count : itemsToComplete.count
            }}
          </div>
          <button v-if="false && zeigeFortschritt" :disabled="itemsCompleted.quantity == 0" class="button-menu icon-download"
            @click.stop="downloadDocuments()">
            Dokument downloaden
          </button>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "UnterlagenKategorie",
  props: ["kategorie", "id", "zeigeFortschritt"],
  data: function () {
    return {};
  },
  methods: {
    downloadDocuments() {
      return false;
    }
  },
  computed: {
    itemsToComplete() {
      let quantity = 0;
      let count = 0;

      for (let i = 0; i < this.kategorie.ueberschriften.length; i++) {
        const ueberschrift = this.kategorie.ueberschriften[i];
        for (let a = 0; a < ueberschrift.items.length; a++) {
          const item = ueberschrift.items[a];
          if (item.toComplete) {
            quantity++;
          }
          count++;
        }
      }
      return {
        quantity: quantity,
        count: count,
      };
    },

    itemsCompleted() {
      let quantity = 0;
      let count = 0;

      for (let i = 0; i < this.kategorie.ueberschriften.length; i++) {
        const ueberschrift = this.kategorie.ueberschriften[i];
        for (let a = 0; a < ueberschrift.items.length; a++) {
          const item = ueberschrift.items[a];
          if (item.erledigt && item.erledigt === true) {
            quantity++;
          }
          if (item.toComplete && item.toComplete === true) {
            count++;
          }

        }
      }
      return {
        quantity: quantity,
        count: count,
      };
    },
  },
  methods: {
    itemClicked() {
      this.$emit("itemClicked");
    },
    downloadDocuments() {
      console.log(this.kategorie);
      console.log("trigger 2");

      for (let u = 0; u < this.kategorie.ueberschriften.length; u++) {
        const ueberschrift = this.kategorie.ueberschriften[u];

        for (let i = 0; i < ueberschrift.items.length; i++) {
          const item = ueberschrift.items[i];
          if(item.Files && item.Files.length > 0){
            for (let f = 0; f < item.Files.length; f++) {
              const file = item.Files[f];
              console.log(file);      
            }
            
            
          }
          
        }

        
      }

      let title = this.kategorie.title;

    },
  },
  mounted: async function () { },
};
</script>

<style scoped>
.v-application a .disabled {
  cursor: default !important;
}
</style>
