<template>
  <div class="block-action checklist">
    <div class="action-header">
      <h3>{{ kategorieUeberschrift.title }}</h3>
      <div class="header-progress">Vollständigkeit: <span
          :class="['tag', itemsToCompleteSum.erledigt == itemsToCompleteSum.count ? 'complete' : 'progress']">{{
              itemsToCompleteSum.erledigt
          }}/{{ itemsToCompleteSum.count }}</span>
      </div>
    </div>
    <div class="action-list" :key="componentKey">
      <div  style="cursor: pointer" :class="['action-item',]" v-for="item in kategorieUeberschrift.items.filter(i => i.toComplete === true)" :key="item.id">
        <unterlagenKategorieUeberschriftItemCustomer :categoryName="categoryName" :categoryHeadline="kategorieUeberschrift.title" :kategorieUeberschriftItem="item" @collapseAllItems="collapseAllItems" />
      </div>
    </div>
  </div>
</template>


<script>

import unterlagenKategorieUeberschriftItemCustomer from "@/components/unterlagenKategorieUeberschriftItemCustomer";


export default {
  name: "UnterlagenKategorieUeberschriftCustomer",
  components: {
    unterlagenKategorieUeberschriftItemCustomer
  },
  props: ["categoryName","kategorieUeberschrift", "id"],
  data: function () {
    return {
      componentKey: 0,
    };
  },

  computed: {
    itemsToCompleteSum() {
      let ret = {
        count: 0,
        erledigt: 0
      }
      for (let i = 0; i < this.kategorieUeberschrift.items.length; i++) {
        const item = this.kategorieUeberschrift.items[i];
        if (item.toComplete === true) {
          if (item.erledigt === true) {
            ret.erledigt++;
          }
          ret.count++;
        }
      }
      return ret;
    }
  },
  methods: {
    collapseAllItems() {
      this.$emit("collapseAllItems");
    },
    log(x){
      console.log(this);
    }
  },
  mounted: async function () {

  },
};
</script>