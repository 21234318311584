var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{class:['action-item', _vm.disable?'disabled':''],on:{"click":function($event){return _vm.itemClicked()}}},[(_vm.disable)?_c('div',{staticClass:"tooltip"},[_vm._v("Wird freigeschaltet, wenn Zusage erfolgt")]):_vm._e(),_c('div',{class:[
        'item-title',
        _vm.zeigeFortschritt === true
          ? _vm.itemsCompleted.quantity == _vm.itemsCompleted.count
            ? 'complete'
            : 'progress'
          : '' ]},[_c('h4',[_vm._v(_vm._s(_vm.kategorie.title))]),_c('div',{staticClass:"button-group"},[_c('div',{class:[
            'tag',
            _vm.zeigeFortschritt === true
              ? _vm.itemsCompleted.quantity == _vm.itemsCompleted.count
                ? 'complete'
                : 'progress'
              : '' ]},[_vm._v(" "+_vm._s(_vm.itemsToComplete.erledigt)+"/"+_vm._s(_vm.itemsToComplete.count)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }