import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('loading',{attrs:{"active":_vm.isLoading,"color":'#78AEF5',"can-cancel":false,"is-full-page":true}}),_c(VDialog,{attrs:{"persistent":"","width":"700"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 lighten-2",staticStyle:{"background-color":"#f4f9fe"}},[_vm._v(" Datei neu anfordern ")]),_c('br'),_c(VCardText,[_c('p',{staticStyle:{"font-size":"20px"}},[_vm._v(" Mit welchem Grund soll die Datei '"+_vm._s(_vm.fileToDecline.name)+"' erneut angefordert werden? ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.declineText),expression:"declineText"}],attrs:{"type":"text"},domProps:{"value":(_vm.declineText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.declineText=$event.target.value}}})]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.declineFile}},[_vm._v("Ablehnen")]),_c(VBtn,{attrs:{"color":"danger","text":""},on:{"click":_vm.abort}},[_vm._v("Abbruch")])],1)],1)],1),_c('div',{class:[
      'item-title',
      _vm.editmode === true ? 'radio' : '',
      _vm.erledigt === true && _vm.editmode === false ? 'ueberschrifterledigt' : '' ],on:{"click":function($event){return _vm.itemClicked()}}},[_c('span',[_vm._v(_vm._s(_vm.kategorieUeberschriftItem.title))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.mitVorlage === true ? " (inkl. Vorlage)" : ""))]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.erledigt === true && _vm.editmode === true ? " (bereits hochgeladen)" : "")+" ")])]),(_vm.kategorieUeberschriftItem.erledigt && !_vm.editmode)?_c('div',[(_vm.kategorieUeberschriftItem.answers)?_c('div',[_c('span',[_vm._v("Antwort: ")]),_c('span',[_vm._v(_vm._s(_vm.kategorieUeberschriftItem.answer.title))]),_c('br'),(_vm.kategorieUeberschriftItem.answer.subquestion)?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.kategorieUeberschriftItem.answer.subquestion.title)),_c('br')]),_c('span',[_vm._v("Antwort: ")]),_c('span',[_vm._v(_vm._s(_vm.kategorieUeberschriftItem.answer.subquestion.answer))])]):_vm._e()]):_vm._e(),(
        !_vm.kategorieUeberschriftItem.answers && _vm.kategorieUeberschriftItem.answer
      )?_c('div',[_c('span',[_vm._v("Antwort: ")]),_c('span',[_vm._v(_vm._s(_vm.kategorieUeberschriftItem.answer))])]):_vm._e()]):_vm._e(),_vm._l((_vm.kategorieUeberschriftItem.Files),function(file){return _c('div',{key:file.id},[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-upload"},[_c('button',{class:[
            file.declined ? '' : 'button-secondary',
            file.declined ? '' : 'icon-download',
            file.declined ? 'rejected' : '',
            'button-green' ],on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(" "+_vm._s(file.name)+" herunterladen")]),_c('br'),(!file.declined)?_c('button',{class:['button-secondary', 'icon-close', 'button-red'],on:{"click":function($event){return _vm.showDeclineFile(file)}}},[_vm._v(" "+_vm._s(file.name)+" ablehnen ")]):_vm._e()])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }