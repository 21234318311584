<template></template>

<script>
import { firestore } from "@/plugins/firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  doc,
  setDoc,
} from "@firebase/firestore";

export default {
  name: "Header",
  mixins: [],
  data() {
    return {
      kategorien: [],
    };
  },
  methods: {
    async loadKats() {
      let result = [];
      let kategorien = await getDocs(
        query(collection(firestore, "kategorien"), orderBy("order", "asc"))
      );

      for (let doc of kategorien.docs) {
        let data = { ...doc.data() };
        data.docId = doc.id;
        result.push(data);
      }
      this.kategorien = result;
    },
    deleteDoc(docId) {
      console.log(docId);
      console.log("delete1");
      let col = collection(firestore, "kategorien");
      let _doc = doc(col, docId);
      console.log("delete1a");

      deleteDoc(_doc);
      console.log("delete2");
    },
    async saveDoc(data) {
      let _collection = collection(firestore, "kategorien/");
      let docId = doc(_collection).id;
      let _doc = doc(_collection, docId);

      setDoc(_doc, data);
    },
  },
  async mounted() {
    await this.loadKats();

    for (let i = 0; i < this.kategorien.length; i++) {
      const kat = this.kategorien[i];
      console.log(kat);
      await this.deleteDoc(kat.docId);
    }

    let data = [];

    let bonitaet = {
      id: 2,
      title: "Bonitätsunterlagen",
      order: 1,
      ueberschriften: [
        {
          title: "Grundsätzliches",
          order: 10,
          items: [
            {
              questType: "documentUpload",
              guid: "2c2afb66-9261-40c9-8b62-14c7a2fde4cf",
              toComplete: false,
              title:
                "Kopie des gültigen Personalausweises bzw. Reisepasses von allen Darlehensnehmern",
              order: 1,
              infoTexts: [
                {
                  order: 1,
                  text: "Bitte achte auf...",
                },
              ],
            },
            {
              questType: "documentUpload",
              guid: "d8b1b819-bb72-4fa9-986a-974d3e5c3c94",
              toComplete: false,
              title: "Niederlassungserlaubnis bei Nicht-EU-Bürgern",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "b5481a60-7a98-4d66-a4b6-a21899f1b8db",
              toComplete: false,
              title:
                "Darlehensvertrag über bereits bestehende Baufinanzierungen sowie Leasing oder Privatkredite",
              order: 3,
            },
            {
              questType: "documentUpload",
              guid: "f7f75551-b4b3-44c2-aedb-b4e2af98d2e0",
              toComplete: false,
              title: "Versicherungsunterlagen",
              order: 4,
            },
            {
              questType: "documentUpload",
              guid: "e1aad843-f5c6-40ef-a00b-2925a1af3929",
              toComplete: false,
              title:
                "Schufa-Erklärung der BHW unterschrieben (siehe Dokument anbei)",
              order: 5,
              Vorlage: "b3bc9a91-49bb-4ad8-be4a-262be7f67669",
            },
            {
              questType: "documentUpload",
              guid: "0ba2edfd-5550-4416-a184-d35d51ff799d",
              toComplete: false,
              title:
                "Eigenkapitalnachweis, ggf. Erklärung zur Schenkung/Privatdarlehen",
              order: 6,
            },
            {
              questType: "documentUpload",
              guid: "a592497e-644d-413a-b518-38ab1975be21",
              toComplete: false,
              title: "Angaben zum Berufsstand, Geburtsdaten der Kinder",
              order: 7,
            },
          ],
        },

        {
          title: "Nicht selbstständige Darlehensnehmer",
          order: 20,
          items: [
            {
              questType: "documentUpload",
              guid: "da51d6df-03ec-4c48-aa0f-231a8ce2646e",
              toComplete: false,
              title:
                "Die letzten 3 aktuelle Einkommensnachweise für Haupt- und Nebenjobs oder bei Bezügemitteilungen die letzten 2 Abrechnungen. Bei schwankenden Einnahmen und Bonizahlungen die Dezember- Abrechnung des Vorjahres",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "a8a3ebc3-f51d-45c9-aa59-0882ffd7ad69",
              toComplete: false,
              title: "Arbeitsvertrag bei Beschäftigungsdauer kleiner 1 Jahr",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "c8abd6bd-4d10-4c27-8464-d9ae97386f95",
              toComplete: false,
              title:
                "Renten-, Pensionsbezüge bzw. Renteninformation aller Darlehensnehmer",
              order: 3,
            },
            {
              questType: "documentUpload",
              guid: "9b6d53f9-0fc3-45c6-a726-e538024424e2",
              toComplete: false,
              title:
                "Bei Mieteinnahmen Steuerbescheide der letzten 2 Jahre zzgl. Steuererklärungen mit allen Anlagen (insbesondere V+V)",
              order: 4,
            },
          ],
        },

        {
          title: "Selbstständige Darlehensnehmer",
          order: 30,
          items: [
            {
              questType: "documentUpload",
              guid: "478fba92-705b-4273-aed2-b7dc6a131b58",
              toComplete: false,
              title: "2 letzten Einkommensteuerbescheide",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "260dd144-88fb-4c31-8d49-6ee7fc5e44cc",
              toComplete: false,
              title: "2 letzten Einkommenssteuererklärungen mit allen Anlagen",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "8ce083a7-9b05-4dcb-854b-a5d31177a892",
              toComplete: false,
              title:
                "2 letzten vollständigen Jahresabschlüsse, z.B. Bilanzen mit G+V, Einnahmen-/Überschussrechnungen, Gewinnermittlungen",
              order: 3,
            },
            {
              questType: "documentUpload",
              guid: "93fe2196-f2c0-44c1-9933-9f1d109865a8",
              toComplete: false,
              title: "aktuelle BWA mit Summen- und Saldenliste",
              order: 4,
            },
          ],
        },

        {
          title: "Zusätzliches (wenn zutreffend)",
          order: 40,
          items: [
            {
              questType: "documentUpload",
              guid: "12d5f034-9cf5-4018-ba35-1d7a2ca0291f",
              toComplete: false,
              title:
                "Bei Vermietung: Mietverträge einschl. Nachträge + 3 Monate Kontoauszüge mit den Mieteingängen",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "db47a162-1fe7-48ae-8d67-32cde0018819",
              toComplete: false,
              title:
                "Bei privater Krankenkasse, aktueller Nachweis über den Beitrag",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "90e69e85-f8c0-4e08-af2a-a24ae83a95a7",
              toComplete: false,
              title:
                "Bei bestehenden Immobilien: aktueller Grundbuchauszug (max. 3 Monate alt) jeder weiteren Immobilie -> Alternativ Vollmacht unterschreiben",
              order: 3,
            },
            {
              questType: "documentUpload",
              guid: "7ebc5135-62a6-4b78-baed-e90f21e39ab9",
              toComplete: false,
              title:
                "Nachweise über sonstige regelmäßige Zahlungsverpflichtungen wie Eigenmiete, wenn diese nach Finanzierung nicht entfällt, (Mietverträge einschl. Nachträge), Unterhaltszahlungen",
              order: 4,
            },
            {
              questType: "documentUpload",
              guid: "4850436e-f37e-4f89-a84d-5c698165e22a",
              toComplete: false,
              title: "Scheidungsvertrag inklusive Folgevereinbarungen",
              order: 5,
            },
            {
              questType: "documentUpload",
              guid: "3a12234d-f80d-4bfc-9ff2-d1f6cf2ad052",
              toComplete: false,
              title:
                "Unterhaltsbescheid(e) und Kontoauszüge der letzten 3 Monate",
              order: 6,
            },
            {
              questType: "documentUpload",
              guid: "85d6d5ed-cd40-400c-af7b-af3e14bd2363",
              toComplete: false,
              title: "Zweizeiler Erklärung Elternzeit",
              order: 7,
              DynTemplate: {
                filename: "Vorlage Elternzeit.pdf",
                template:
                  "Hiermit erkläre ich, {{Anrede}} {{Vorname}} {{Nachname}}, wohnhaft in {{Postleitzahl}} {{Wohnort}}, {{Strasse}} {{Hausnummer}}, dass " +
                  "ich nach Ablauf meiner Elternzeit wieder wie vor Beginn meiner Elternzeit bei meinem Arbeitgeber, " +
                  "der Firma {{Arbeitgeber}} die Arbeit wieder im gleichen Umfang/reduziert auf {{AnzahlStunden}} Stunden " +
                  "aufnehmen werde. \n\n\n" +
                  "{{Unterschriftsort}}, den {{Datum}}\n\n\n\n\n\n\n" +
                  "___________________________\n" +
                  "Unterschrift Darlehensnehmer ",
                fields: [
                  {
                    title: "Anrede",
                    field: "Anrede",
                    type: "String",
                    default: "Anrede",
                  },
                  {
                    title: "Vorname",
                    field: "Vorname",
                    type: "String",
                    default: "Vorname",
                  },
                  {
                    title: "Nachname",
                    field: "Nachname",
                    type: "String",
                    default: "Nachname",
                  },

                  {
                    title: "Postleitzahl",
                    field: "Postleitzahl",
                    type: "String",
                    default: "Plz",
                  },
                  {
                    title: "Wohnort",
                    field: "Wohnort",
                    type: "String",
                    default: "Ort",
                  },
                  {
                    title: "Straße",
                    field: "Strasse",
                    type: "String",
                    default: "Strasse",
                  },
                  {
                    title: "Hausnummer",
                    field: "Hausnummer",
                    type: "String",
                    default: "Hausnummer",
                  },

                  {
                    title: "Arbeitgeber",
                    field: "Arbeitgeber",
                    type: "String",
                  },

                  {
                    title: "Anzahl Stunden bei der Wiederaufnahme der Arbeit",
                    field: "AnzahlStunden",
                    type: "Number",
                  },

                  {
                    title: "Datum",
                    field: "Datum",
                    type: "Date",
                  },
                  {
                    title: "Ort (Unterschrift)",
                    field: "Unterschriftsort",
                    type: "String",
                    default: "Ort",
                  },
                ],
              },
            },
            {
              questType: "documentUpload",
              guid: "9298f54b-6868-44a8-9ae6-77f928e58168",
              toComplete: false,
              title: "Erklärung Verkauf Rentenalter",
              order: 8,
              DynTemplate: {
                filename: "Erklaerung Verkauf Renteneintritt.pdf",
                template_solo:
                  "Hiermit erkläre ich, {{Anrede}} {{Vorname}} {{Nachname}}, wohnhaft in {{Plz}} {{Ort}}, {{Strasse}} {{Hausnummer}}, dass " +
                  "ich zum Renteneintritt von {{Anrede_Renteneintritt}} {{Vorname_Renteneintritt}} {{Nachname_Renteneintritt}} am {{Datum_Renteneintritt}} die Immobilie in {{Plz_Immobilie}} {{Ort_Immobilie}}, {{Strasse_Immobilie}} {{Hausnummer_Immobilie}}, verkaufen werde. \n\n\n" +
                  "{{Ort_Unterschrift}}, den {{Datum_Unterschrift}}\n\n\n\n\n\n\n" +
                  "___________________________\n" +
                  "Unterschrift Darlehensnehmer ",

                template_partner:
                  "Hiermit erklären wir, {{Anrede}} {{Vorname}} {{Nachname}} und {{Anrede_Partner}} {{Vorname_Partner}} {{Nachname_Partner}}, wohnhaft in {{Plz}} {{Ort}}, {{Strasse}} {{Hausnummer}}, dass " +
                  "wir zum Renteneintritt von {{Anrede_Renteneintritt}} {{Vorname_Renteneintritt}} {{Nachname_Renteneintritt}} am {{Datum_Renteneintritt}} die Immobilie in {{Plz_Immobilie}} {{Ort_Immobilie}}, {{Strasse_Immobilie}} {{Hausnummer_Immobilie}}, verkaufen werden. \n\n\n" +
                  "{{Ort_Unterschrift}}, den {{Datum_Unterschrift}}\n\n\n\n\n\n\n" +
                  "_______________________________________________\n" +
                  "Unterschriften Darlehensnehmer ",
                fields: [
                  {
                    title: "Anrede",
                    field: "Anrede",
                    type: "String",
                    default: "Anrede",
                  },
                  {
                    title: "Vorname",
                    field: "Vorname",
                    type: "String",
                    default: "Vorname",
                  },
                  {
                    title: "Nachname",
                    field: "Nachname",
                    type: "String",
                    default: "Nachname",
                  },

                  {
                    title: "Anrede Partner",
                    field: "Anrede_Partner",
                    type: "String",
                    default: "Anrede_Partner",
                    ifPartner: true,
                  },
                  {
                    title: "Vorname Partner",
                    field: "Vorname_Partner",
                    type: "String",
                    default: "Vorname_Partner",
                    ifPartner: true,
                  },
                  {
                    title: "Nachname Partner",
                    field: "Nachname_Partner",
                    type: "String",
                    default: "Nachname_Partner",
                    ifPartner: true,
                  },

                  {
                    title: "Postleitzahl",
                    field: "Plz",
                    type: "String",
                    default: "Plz",
                  },
                  {
                    title: "Wohnort",
                    field: "Ort",
                    type: "String",
                    default: "Ort",
                  },
                  {
                    title: "Straße",
                    field: "Strasse",
                    type: "String",
                    default: "Strasse",
                  },
                  {
                    title: "Hausnummer",
                    field: "Hausnummer",
                    type: "String",
                    default: "Hausnummer",
                  },

                  {
                    title: "Anrede (geht in Rente)",
                    field: "Anrede_Renteneintritt",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Vorname (geht in Rente)",
                    field: "Vorname_Renteneintritt",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Nachname (geht in Rente)",
                    field: "Nachname_Renteneintritt",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Datum Renteneintritt",
                    field: "Datum_Renteneintritt",
                    type: "Date",
                    default: "",
                  },

                  {
                    title: "Straße Immobilie",
                    field: "Strasse_Immobilie",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Hausnummer Immobilie",
                    field: "Hausnummer_Immobilie",
                    type: "Number",
                    default: "",
                  },
                  {
                    title: "Postleitzahl Immobilie",
                    field: "Plz_Immobilie",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Ort Immobilie",
                    field: "Ort_Immobilie",
                    type: "String",
                    default: "",
                  },

                  {
                    title: "Ort (Unterschrift)",
                    field: "Ort_Unterschrift",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Datum (Unterschrift)",
                    field: "Datum_Unterschrift",
                    type: "Date",
                    default: "",
                  },
                ],
              },
            },
            {
              questType: "documentUpload",
              guid: "f1da9850-a4b6-48ca-b76c-1dc681b4ceb8",
              toComplete: false,
              title: "Schenkungserklärung",
              order: 9,
              DynTemplate: {
                filename: "Schenkungserklaerung.pdf",
                template:
                  "Hiermit erkläre ich, {{Anrede}} {{Vorname}} {{Nachname}}, wohnhaft in {{Plz}} {{Ort}}, {{Strasse}} {{Hausnummer}}, dass ich {{Vorname_Beschenkter}} {{Nachname_Beschenkter}}, " +
                  "wohnhaft in {{Strasse_Beschenkter}} {{Hausnummer_Beschenkter}}, {{Plz_Beschenkter}} {{Ort_Beschenkter}}, einen Betrag von {{Schenkungssumme}}€ zum Ankauf der Immobilie {{Strasse_zks_Immobilie}} {{Hausnummer_zks_Immobilie}}, {{Plz_zks_Immobilie}} {{Ort_zks_Immobilie}}, schenken werde. Einen Nachweis über den Schenkungsbetrag füge ich dieser Erklärung bei. \n\n\n" +
                  "{{Ort_Unterschrift}}, den {{Datum_Unterschrift}}\n\n\n\n\n\n\n" +
                  "___________________________\n" +
                  "Unterschrift Schenkender ",

                fields: [
                  {
                    title: "Anrede",
                    field: "Anrede",
                    type: "String",
                    default: "Anrede",
                  },
                  {
                    title: "Vorname",
                    field: "Vorname",
                    type: "String",
                    default: "Vorname",
                  },
                  {
                    title: "Nachname",
                    field: "Nachname",
                    type: "String",
                    default: "Nachname",
                  },

                  {
                    title: "Postleitzahl",
                    field: "Plz",
                    type: "String",
                    default: "Plz",
                  },
                  {
                    title: "Wohnort",
                    field: "Ort",
                    type: "String",
                    default: "Ort",
                  },
                  {
                    title: "Straße",
                    field: "Strasse",
                    type: "String",
                    default: "Strasse",
                  },
                  {
                    title: "Hausnummer",
                    field: "Hausnummer",
                    type: "String",
                    default: "Hausnummer",
                  },

                  {
                    title: "Vorname (Beschenkter)",
                    field: "Vorname_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Nachname (Beschenkter)",
                    field: "Nachname_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Straße (Beschenkter)",
                    field: "Strasse_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Hausnummer (Beschenkter)",
                    field: "Hausnummer_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Plz (Beschenkter)",
                    field: "Plz_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Ort (Beschenkter)",
                    field: "Ort_Beschenkter",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Schenkungssumme",
                    field: "Schenkungssumme",
                    type: "String",
                    default: "",
                  },

                  {
                    title: "Straße (zum Kauf stehende Immobilie)",
                    field: "Strasse_zks_Immobilie",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Hausnummer (zum Kauf stehende Immobilie)",
                    field: "Hausnummer_zks_Immobilie",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Postleitzahl (zum Kauf stehende Immobilie)",
                    field: "Plz_zks_Immobilie",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Ort (zum Kauf stehende Immobilie)",
                    field: "Ort_zks_Immobilie",
                    type: "String",
                    default: "",
                  },

                  {
                    title: "Ort (Unterschrift)",
                    field: "Ort_Unterschrift",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Datum (Unterschrift)",
                    field: "Datum_Unterschrift",
                    type: "Date",
                    default: "",
                  },
                ],
              },
            },

            {
              questType: "documentUpload",
              guid: "9d527356-09db-4c20-992d-71f1a29f361a",
              toComplete: false,
              title: "Sparratenverzicht",
              order: 9,
              DynTemplate: {
                filename: "Sparratenverzicht.pdf",
                template_solo:
                  "Hiermit erkläre ich, {{Anrede}} {{Vorname}} {{Nachname}}, wohnhaft in {{Plz}} {{Ort}}, {{Strasse}} {{Hausnummer}}, dass " +
                  "ich folgende Verträge nicht mehr besparen werde: \n\n{{__VERTRAEGE__}} \n\n\n" +
                  "{{Ort_Unterschrift}}, den {{Datum_Unterschrift}}\n\n\n\n\n\n\n" +
                  "___________________________\n" +
                  "Unterschrift Darlehensnehmer ",

                template_partner:
                  "Hiermit erklären wir, {{Anrede}} {{Vorname}} {{Nachname}} und {{Anrede_Partner}} {{Vorname_Partner}} {{Nachname_Partner}}, wohnhaft in {{Plz}} {{Ort}}, {{Strasse}} {{Hausnummer}}, dass " +
                  "wir folgende Verträge nicht mehr besparen werden: \n\n{{__VERTRAEGE__}}. \n\n\n" +
                  "{{Ort_Unterschrift}}, den {{Datum_Unterschrift}}\n\n\n\n\n\n\n" +
                  "_______________________________________________\n" +
                  "Unterschriften Darlehensnehmer ",
                fields: [
                  {
                    title: "Anrede",
                    field: "Anrede",
                    type: "String",
                    default: "Anrede",
                  },
                  {
                    title: "Vorname",
                    field: "Vorname",
                    type: "String",
                    default: "Vorname",
                  },
                  {
                    title: "Nachname",
                    field: "Nachname",
                    type: "String",
                    default: "Nachname",
                  },

                  {
                    title: "Anrede Partner",
                    field: "Anrede_Partner",
                    type: "String",
                    default: "Anrede_Partner",
                    ifPartner: true,
                  },
                  {
                    title: "Vorname Partner",
                    field: "Vorname_Partner",
                    type: "String",
                    default: "Vorname_Partner",
                    ifPartner: true,
                  },
                  {
                    title: "Nachname Partner",
                    field: "Nachname_Partner",
                    type: "String",
                    default: "Nachname_Partner",
                    ifPartner: true,
                  },

                  {
                    title: "Postleitzahl",
                    field: "Plz",
                    type: "String",
                    default: "Plz",
                  },
                  {
                    title: "Wohnort",
                    field: "Ort",
                    type: "String",
                    default: "Ort",
                  },
                  {
                    title: "Straße",
                    field: "Strasse",
                    type: "String",
                    default: "Strasse",
                  },
                  {
                    title: "Hausnummer",
                    field: "Hausnummer",
                    type: "String",
                    default: "Hausnummer",
                  },

                  {
                    title: "Ort (Unterschrift)",
                    field: "Ort_Unterschrift",
                    type: "String",
                    default: "",
                  },
                  {
                    title: "Datum (Unterschrift)",
                    field: "Datum_Unterschrift",
                    type: "Date",
                    default: "",
                  },

                  {
                    title: "Verträge:",
                    field: "__VERTRAEGE__",
                    type: "String",
                    default: "__ARRAY__",
                    buttonText: "Vertrag hinzufügen",
                    objToAdd: {
                      Vertragsnummer: "",
                      Institut: "",
                    },
                    subFields: [
                      {
                        title: "Vertragsnummer",
                        field: "Vertragsnummer",
                      },
                      {
                        title: "Institut",
                        field: "Institut",
                      },
                    ],
                    multiple: true,
                  },
                ],
              },
            },
          ],
        },
      ],
    };

    let objekt = {
      id: 3,
      title: "Objektunterlagen",
      order: 2,
      ueberschriften: [
        {
          title: "Kauf Immobilie",
          order: 10,
          items: [
            {
              questType: "documentUpload",
              guid: "fdf68cd0-537c-4e9d-a6dd-a449462ca055",
              toComplete: false,
              title:
                "aktueller Grundbuchauszug (max. 3 Monate alt) oder Kaufvertrag ggf. vorab Entwurf -> Alternativ Vollmacht unterschreiben",
              order: 1,
              Vorlage: "7e7291e0-0d85-49da-9e3e-4b001552fe3e",
            },
            {
              questType: "documentUpload",
              guid: "42e80bd5-f96c-4f53-8cd1-6b925f658a4e",
              toComplete: false,
              title:
                "4 Lichtbilder (2 Farb-Fotos Außen: Vorderseite, Seitenansicht, wenn Seitenansicht nicht möglich Rückseite / 2 Farb-Fotos Innen: Wohnzimmer, Bad)",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "cf8e2084-9f2c-464d-8ff4-b4b52a598156",
              toComplete: false,
              title:
                "Baubeschreibung (Bei Objekten gleich oder jünger 20 Jahre)",
              order: 3,
            },
            {
              questType: "documentUpload",
              guid: "f7cc2cea-a33e-457c-98ab-28f4cfaffd87",
              toComplete: false,
              title: "Bemaßte Bau- / Grundriss- / Schnittzeichnungen",
              order: 4,
            },
            {
              questType: "documentUpload",
              guid: "f4599d1b-7c32-4129-a073-7b87f24681d5",
              toComplete: false,
              title: "Berechnung Wohn- / Nutzfläche",
              order: 5,
            },
            {
              questType: "documentUpload",
              guid: "1902bad0-3a1d-409a-ba38-bd9bf6be8751",
              toComplete: false,
              title: "Lageplan / Flurkarte",
              order: 6,
            },
            {
              questType: "documentUpload",
              guid: "97f64764-ebcf-4544-bc4b-bd964d8d4d7b",
              toComplete: false,
              title:
                "Aufstellung der Modernisierungskosten (Höhe der einzelnen Gewerke und Angaben zu Eigenleistungen)",
              order: 7,
            },
            {
              questType: "documentUpload",
              guid: "9e25bd78-e533-4240-a68a-70354149e8df",
              toComplete: false,
              title:
                "Eigentumswohnung: Teilungserklärung nebst Nachträgen u. Teilungsplan",
              order: 8,
            },
          ],
        },

        {
          title: "Zusätzlich bei Neubau und Kauf mit Kernsanierungen",
          order: 20,
          items: [
            {
              questType: "documentUpload",
              guid: "06764692-4bf4-4e63-b2ae-f36b2aad93b5",
              toComplete: false,
              title: "Werkvertrag bzw. notarieller Kaufvertrag (ggf. Entwurf)",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "b9b6475d-fbae-4a7a-867f-f8e3b7631543",
              toComplete: false,
              title: "Baukostenzusammenstellung inkl. Eigenleistungsnachweis",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "72087c3b-d629-45b8-a2bc-2978046ff1e7",
              toComplete: false,
              title:
                "„Online-Bestätigung zum Kreditantrag“ (sofern KfW Programm 151, 152, 153 beantragt wird)",
              order: 3,
            },
          ],
        },
      ],
    };

    let gesundheitsfragen = {
      id: 4,
      title: "Gesundheitsfragen",
      order: 3,
      ueberschriften: [
        {
          title: "Gesundheitsfragen",
          order: 10,
          items: [
            {
              questType: "question",
              guid: "b417a644-5690-422c-bc31-62f4dbb3392a",
              toComplete: false,
              type: "int",
              title: "Körpergröße in cm",
              order: 1,
            },
            {
              questType: "question",
              guid: "7eb4c375-b7d4-49ba-b22f-3b2849e86c2d",
              toComplete: false,
              type: "int",
              title: "Gewicht in KG",
              order: 2,
            },
            {
              questType: "question",
              guid: "4b3c9dd1-4c9d-4954-8ebe-52ee56923680",
              toComplete: false,
              type: "bool",
              title:
                "Besteht oder bestand bei Ihnen eine Schwerbehinderung (GdB, Schwerbehindertenausweis) oder eine Erwerbsminderung (MdE)?",
              order: 3,
              answers: [
                {
                  title: "ja",
                  subquestion: {
                    title:
                      "Grund und Grad der Schwerbehinderung oder Erwerbsminderung?",
                    type: "string",
                  },
                },
                {
                  title: "nein",
                },
              ],
            },
            {
              questType: "question",
              guid: "f58e4935-3086-4f23-ab61-b26b1bd7d92d",
              toComplete: false,
              title:
                "Werden Sie derzeit untersucht, behandelt oder wurden Sie in den letzten fünf Jahren aus gesundheitlichen Gründen länger als vier Wochen regelmäßig oder bedarfsweise behandelt?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                  subquestion: {
                    title: "Inwiefern?",
                    type: "string",
                  },
                },
                {
                  title: "nein",
                },
              ],
              order: 4,
            },
            {
              questType: "question",
              guid: "71c3e005-6a03-41cf-9a63-2e14610127d9",
              toComplete: false,
              title:
                "Wer ist Ihr Hausarzt bzw. welcher Arzt ist über Ihre Gesundheitsverhältnisse am besten informiert?",
              type: "string",
              order: 5,
            },
            {
              questType: "question",
              guid: "fb0ec969-2cb0-4c57-aa59-08ed714dab46",
              toComplete: false,
              title:
                "Haben Sie in den letzten zwölf Monaten vor Vertragsabschluss Zigaretten oder ähnliches konsumiert?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                },
                {
                  title: "nein",
                },
              ],
              order: 6,
            },
            {
              questType: "question",
              guid: "27bf4bc4-90f8-4ced-8fcd-b78e7c700646",
              toComplete: false,
              title: "Besitzen Sie Wohneigentum?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                },
                {
                  title: "nein",
                },
              ],
              order: 7,
            },
            {
              questType: "question",
              guid: "9eb3391b-4264-4eb8-8585-8edca4f9dd1e",
              toComplete: false,
              title: "Mehr als 75% Bürotätigkeit?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                },
                {
                  title: "nein",
                },
              ],
              order: 8,
            },
            {
              questType: "question",
              guid: "6974cb5d-98d1-42e9-8fef-8ec4d8b34268",
              toComplete: false,
              title: "Sind Sie Student?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                },
                {
                  title: "nein",
                },
              ],
              order: 9,
            },
            {
              questType: "question",
              guid: "cf503fa3-e9c3-4cca-9599-4e12775d8bc2",
              toComplete: false,
              title: "Sind Sie Akademiker?",
              type: "bool",
              answers: [
                {
                  title: "ja",
                },
                {
                  title: "nein",
                },
              ],
              order: 10,
            },
          ],
        },
      ],
    };

    let auszahlung = {
      id: 5,
      title: "Auszahlungsunterlagen",
      order: 4,
      ueberschriften: [
        {
          title: "Auszahlung Kaufpreis",
          order: 10,
          items: [
            {
              questType: "documentUpload",
              guid: "710f994e-81ee-460a-aff2-9d9b49438b7e",
              toComplete: false,
              title: "Nachweis: Überweisung des Eigenanteils",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "03da31fc-dfe6-48ef-9672-524b64400eea",
              toComplete: false,
              title: "Zahlungsauftrag",
              order: 2,
            },
          ],
        },

        {
          title: "Auszahlung Modernisierungen",
          order: 10,
          items: [
            {
              questType: "documentUpload",
              guid: "bcb0beaa-9c52-49f5-95d7-95378e5de0ab",
              toComplete: false,
              title: "Lichtbilder des Baufortschritts",
              order: 1,
            },
            {
              questType: "documentUpload",
              guid: "8a7b8285-a909-41d2-aa7f-4a99fdbbc9f4",
              toComplete: false,
              title: "Bautenstandsbericht / Baufortschrittsanzeige",
              order: 2,
            },
            {
              questType: "documentUpload",
              guid: "ea91c0ec-c0eb-46d9-be96-ff78e7f6bfe1",
              toComplete: false,
              title: "Zahlungsauftrag",
              order: 3,
            },
          ],
        },
      ],
    };

    data.push(bonitaet);
    data.push(objekt);
    data.push(gesundheitsfragen);
    data.push(auszahlung);

    for (let i = 0; i < data.length; i++) {
      const kat = data[i];
      this.saveDoc(kat);
    }
    console.log(data);
  },
};
</script>
