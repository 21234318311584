<template>
  <div>
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>

    <div id="page-login">
      <form class="login">
        <div class="header-login">
          <div class="login-title">Kundenportal</div>
          <div class="login-logo">
            <img src="@/assets/images/logo-ft_login.svg" alt="Florian Thoms" />
          </div>
        </div>
        <div class="login-form">
          <div class="form-field">
            <label for="newpw1"><b>Bitte vergib ein Passwort</b></label>
          </div>
          <div class="form-field">
            <label for="email">Email</label>
            <input
              autocomplete="off"
              name="email"
              type="email"
              v-model="email"
              disabled
            />
          </div>
          <div class="form-field">
            <label for="newpw1">Neues Passwort</label>
            <input
              autocomplete="off"
              name="newpw1"
              type="password"
              v-model="newpw1"
            />
          </div>
          <div class="form-field">
            <label for="newpw2">Passwort wiederholen</label>
            <input
              autocomplete="off"
              name="newpw2"
              type="password"
              v-model="newpw2"
            />
          </div>
          <p id="p-error-msg" style="color: red">{{ errorMsg }}</p>
        </div>
        <div class="login-footer">
          <button
            id="btn-savepw"
            class="button-primary"
            @click.prevent="setpw"
            :disabled="!pwvalid"
          >
            Passwort speichern
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { auth, functions } from "@/plugins/firebase.js";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { httpsCallable } from "@firebase/functions";

import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

export default {
  name: "SetPassword",
  mixins: [firestoreFunctionsMixin],
  components: {},
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
      isLoading: true,
      newpw1: "",
      newpw2: "",
      uid: "",
    };
  },
  methods: {
    async setpw() {
      this.isLoading = true;
      const setPasswordForNewUser = httpsCallable(
        functions,
        "setPasswordForNewUser"
      );
      const response = await setPasswordForNewUser({
        uid: this.uid,
        pw: this.newpw1,
      });

      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.success === true
      ) {
        this.$store.dispatch("showSnackbar", {
          text: `Dein Passwort wurde erfolgreich hinterlegt. Du kannst dich nun anmelden`,
          color: "green",
        });

        this.$router.push({ name: "Login" });
      } else {
      }

      this.isLoading = false;
    },
  },
  computed: {
    pwvalid() {
      let minlen = 8;

      if (this.newpw1.trim().length === 0) {
        this.errorMsg = "";
        return false;
      }

      if (this.newpw1.trim() !== this.newpw2.trim()) {
        this.errorMsg = "Die Passwörter stimmen nicht überein";
        return false;
      }

      if (this.newpw1.trim().length < minlen) {
        this.errorMsg =
          "Das Passwort muss mindestens " + minlen + " Zeichen lang sein";
        return false;
      }
      if (this.newpw2.trim().length < minlen) {
        this.errorMsg =
          "Das Passwort muss mindestens " + minlen + " Zeichen lang sein";
        return false;
      }

      this.errorMsg = "";
      return true;
    },
  },
  mounted: async function () {
    if (this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "DBFrontend" });
    }
    //this.$store.state.isLoading = true;

    let uid = this.$route.params.uid;
    if (uid) {
      const checkIfUserNeedsToSetPassword = httpsCallable(
        functions,
        "checkIfUserNeedsToSetPassword"
      );
      const response = await checkIfUserNeedsToSetPassword({
        uid: uid,
      });
      console.log(response);

      this.isLoading = false;
      if (!response.data || !response.data.valid) {
        this.$router.push({ name: "Login" });
      }

      if (response.data && response.data.user) {
        this.email = response.data.user.email;
        this.uid = response.data.user.uid;
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style>
</style>
      