import {
    firestore
} from "@/plugins/firebase";
import {
    collection,
    doc,
    documentId,
    getDoc,
    getDocs,
    orderBy,
    query,
    where
} from "@firebase/firestore";


export default {
    data() {
        return {


        }
    },
    created: function () {

    },
    methods: {
        async loadcustomerprofile(uid) {
            const docRef = doc(firestore, "customerprofile", uid);
            const docSnap = await getDoc(docRef);
            let data = docSnap.data();
            if (data) {
                data.uid = uid;
            }
            return data;
        },

        async loademployeeprofile(uid) {
            const docRef = doc(firestore, "employeeprofile", uid);
            const docSnap = await getDoc(docRef);
            let data = docSnap.data();
            if (data) {
                data.uid = uid;
            }


            //console.log("employeeprofile", data);
            return data;
        },

        async loadUserdata(uid) {
            const docRef = doc(firestore, "userdata", uid);
            const docSnap = await getDoc(docRef);

            return docSnap.data();
        },

        async getVertriebspartner(vpId) {
            console.log("In getVertriebspartner");

            const docRef = doc(firestore, "employeeprofile", vpId);
            const docSnap = await getDoc(docRef);
            let data = docSnap.data();

            return data;

        },

        async getAllEmployees() {
            console.log("In getAllEmployees");
            let ret = [];
            let employeeprofiles = await getDocs(
                query(
                    collection(firestore, "employeeprofile")
                )
            );

            for (let doc of employeeprofiles.docs) {
                let data = {
                    ...doc.data()
                };
                data.uid = doc.id;
                ret.push(data);
            }

            return ret;
        },

        istVertreterVon(allEmployees, uid, level = 0) {
            
            let result = [];
            for (let e = 0; e < allEmployees.length; e++) {
                const employee = allEmployees[e];
                if (employee.Vertreter && employee.Vertreter.includes(uid)) {
                    result.push({ employee: employee, level: level });
                }
            }
            
            return result;
        },

        Sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },

        getEmployeesIRepresent(allEmployees, myProfile){
            let checkedUids = [myProfile.uid];
            let employeesDieIchVertrete = [{employee:myProfile, level: 0}];
            employeesDieIchVertrete = employeesDieIchVertrete.concat(this.istVertreterVon(allEmployees, myProfile.uid, 0));
            let result = [];
            let level = 1;
            do {
                level++;
                result = [];
                let _employeesDieIchVertrete = employeesDieIchVertrete;
                
                for (let i = 0; i < employeesDieIchVertrete.length; i++) {
                    const employee = employeesDieIchVertrete[i].employee;
                    if (checkedUids.includes(employee.uid) === false) {
                        checkedUids.push(employee.uid);                       

                        result = this.istVertreterVon(allEmployees, employee.uid, level);
                        if (result.length > 0) {
                            _employeesDieIchVertrete = _employeesDieIchVertrete.concat(result);
                        }
                    }else{
                        //console.log("wurde schon geprüft");
                    }
                }
                employeesDieIchVertrete = _employeesDieIchVertrete;
            }
            while (result.length > 0 && level <= 10)
            return employeesDieIchVertrete;
        },

        async getMyCustomers(allEmployees, myProfile) {
            
            let checkedUids = [myProfile.uid];
            let employeesDieIchVertrete = [{employee:myProfile, level: 0}];
            employeesDieIchVertrete = employeesDieIchVertrete.concat(this.istVertreterVon(allEmployees, myProfile.uid, 0));

            let result = [];
            let level = 1;
            do {
                level++;
                result = [];
                let _employeesDieIchVertrete = employeesDieIchVertrete;
                
                for (let i = 0; i < employeesDieIchVertrete.length; i++) {
                    const employee = employeesDieIchVertrete[i].employee;
                    if (checkedUids.includes(employee.uid) === false) {
                        checkedUids.push(employee.uid);                       

                        result = this.istVertreterVon(allEmployees, employee.uid, level);
                        if (result.length > 0) {
                            _employeesDieIchVertrete = _employeesDieIchVertrete.concat(result);
                        }
                    }else{
                        //console.log("wurde schon geprüft");
                    }
                }
                employeesDieIchVertrete = _employeesDieIchVertrete;
            }
            while (result.length > 0 && level <= 10)

            
            let ret = [];
            let customerprofiles;

            if(myProfile.Rollen.includes("AlleKundenSehen")){
                customerprofiles = await getDocs(
                    query(
                        collection(firestore, "customerprofile"),
                        orderBy("created", "desc")
                    )
                );
            }else{
                customerprofiles = await getDocs(
                    query(
                        collection(firestore, "customerprofile"),
                        where("Vertriebspartner", "in", employeesDieIchVertrete.map(e => e.employee.uid)),
                        orderBy("created", "desc")
                    )
                );
            }

            for (let doc of customerprofiles.docs) {
                let data = {
                    ...doc.data()
                };
                data.uid = doc.id;
                ret.push(data);
            }

            return ret;
        },

    }
}