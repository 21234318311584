<template>
  <div>
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>

    
      <header class="dashboard-header">
        <div class="header-headline">
          <h1>
            Rollen zuweisen: {{ employee.Vorname }} {{ employee.Nachname }}
          </h1>
          <div class="button-group">
            <a
              class="button-tertiary icon-close"
              @click="gotoByName('EmployeesOverview')"
              >Zurück</a
            >
            <button class="button-primary" @click="saveEmployee()">
              Speichern
            </button>
          </div>
        </div>
      </header>

      <div class="dashboard-content">
        <div class="grid-32 4-8">
          <div class="card">
            <div class="card-header">
              <h3>Rollen</h3>
            </div>
            <div class="card-form" :key="componentKey">
              <div class="form-field" v-for="r in allRoles" :key="r.id">
                <v-switch
                  v-bind:input-value="employeeHasRole(employee, r)"
                  @change="(value) => employeeSetRole(employee, r, value)"
                  :label="r"
                ></v-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import imageFunctionsMixin from "@/mixins/imageFunctions.js";
import customFunctionsMixin from "@/mixins/customFunctions.js";

import employee from "@/components/employee.vue";
import kundenUebersicht from "@/components/kundenUebersicht.vue";

import { functions } from "@/plugins/firebase";
import { httpsCallable } from "@firebase/functions";

import { firestore } from "@/plugins/firebase";
import { collection, doc, updateDoc } from "@firebase/firestore";

export default {
  name: "EmployeeSetRoles",
  mixins: [
    routingFunctionsMixin,
    firestoreFunctionsMixin,
    customFunctionsMixin,
    imageFunctionsMixin,
  ],
  components: { kundenUebersicht, employee },

  data() {
    return {
      employee: { Vorname: "", Nachname: "", Rollen: [] },
      componentKey: 0,
      isLoading: false,
      allRoles: [
        "Vertriebspartner",
        "MitarbeiterVerwalten",
        "MitarbeiterLoeschen",
        "MitarbeiterAnlegen",
        "KundenAnlegen",
        "Assistenz"
      ],
    };
  },
  methods: {
    async saveEmployee() {
      let _collection = collection(firestore, "employeeprofile/");
      let _doc = doc(_collection, this.employee.uid);

      let employeeRoles = {
        Rollen: this.employee.Rollen,
      };

      console.log(this.employee.uid, employeeRoles);

      try {
        await updateDoc(_doc, employeeRoles);
        
        
        this.$store.dispatch("showSnackbar", {
          text: "Erfolgreich gespeichert",
          color: "green",
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch("showSnackbar", {
          text: "Speichern fehlgeschlagen",
          color: "red",
        });
      }
    },
    employeeSetRole(employee, role, value) {
      console.log(employee, role, value);

      if (value === false && employee.Rollen.includes(role)) {
        let idx = employee.Rollen.indexOf(role);
        if (idx > -1) {
          employee.Rollen.splice(idx, 1);
        }
      }

      if (value === true && !employee.Rollen.includes(role)) {
        employee.Rollen.push(role);
      }
    },

    employeeHasRole(employee, role) {
      return employee.Rollen.includes(role);
    },
  },
  computed: {
    allowedToSetRoles() {
      return this.$store.state.employeeprofile.Rollen.includes(
        "RollenVergeben"
      );
    },
  },
  async mounted() {
    if (!this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "Login" });
      return;
    }

    if (!this.allowedToSetRoles) {
      this.$router.push({ name: "EmployeesOverview" });
      return;
    }

    let uid = this.$route.params.uid;
    if (!uid || uid.length === 0) {
      this.$router.push({ name: "EmployeesOverview" });
      return;
    }

    this.employee = this.$store.state.allEmployees.find((e) => e.uid == uid);
  },
};
</script>

<style lang="scss" scoped>
</style>