<template>
  <div>
    <div id="page-login">
      <form class="login">
        <div class="header-login">
          <div class="login-title">Kundenportal Backend</div>
          <div class="login-logo">
            <img src="@/assets/images/logo-ft_login.svg" alt="Florian Thoms" />
          </div>
        </div>
        <div class="login-form">
          <div class="form-field">
            <label for="email">Deine E-Mail</label>
            <input
              ref="email"
              name="email"
              type="email"
              v-on:keyup.enter="login"
              v-model="email"
            />
          </div>
          <div class="form-field">
            <label for="password">Passwort</label>
            <input
              name="password"
              type="password"
              v-on:keyup.enter="login"
              v-model="password"
            />
          </div>
        </div>
        <div class="login-footer">
          <input class="button-primary" value="Anmelden" @click="login" />
          <a class="reset-password" @click="resetpw">Passwort vergessen?</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase.js";
import {signInWithEmailAndPassword} from "@firebase/auth";

import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

export default {
  name: "Login",
  mixins: [firestoreFunctionsMixin],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    resetpw() {},
    async login() {
      try {
        this.$store.state.auth.authDone = false;
        let data = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        
        this.$router.push({ name: "DBFrontend" });
      } catch (err) {
        this.error = err.message;
      }
    },
  },
  mounted: function () {
    if (this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "DBFrontend" });
    }

    let input = this.$refs.email;
    input.focus();
  },
};
</script>

<style>
</style>
      