export default {
    data() {
        return {
            

        }
    },
    created: function () {

    },
    methods: {
        gotoByName(routeName){
            this.$router.push({name: routeName});
        }

    }
};