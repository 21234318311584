<template>
  <div>
    <customDialog ref="cstmdlg" />
    <loading
      :active="isLoading"
      :color="'#78AEF5'"
      :can-cancel="false"
      :is-full-page="true"
    />
    <div class="text-center">
      <v-dialog persistent v-model="showCustomDocumentDialog" width="700">
        <v-card>
          <v-card-title
            class="text-h5 lighten-2"
            style="background-color: #f4f9fe"
          >
            Neues Dokument hinzufügen
          </v-card-title>

          <v-card-text>
            <form class="card-form">
              <div class="form-field" style="padding-top: 20px">
                <label id="doctitle">Dokumententitel</label>
                <input
                  ref="doctitle"
                  type="text"
                  name="doctitle"
                  v-model="newDocument.title"
                />
              </div>
            </form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="addCustomDocument">
              Hinzufügen
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                newDocument.title = '';
                showCustomDocumentDialog = false;
              "
            >
              Abbruch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="msg === null && kunde">
      <header class="dashboard-header">
        <div class="header-headline">
          <h1 v-if="activeTab == 1">
            {{ kunde.Vorname }} {{ kunde.Nachname }}
          </h1>

          <div class="button-group">
            <a
              class="button-tertiary icon-close"
              v-if="activeTab == 1"
              @click="gotoByName('DBBackend')"
              >Abbrechen</a
            >
            <a
              class="button-tertiary icon-back"
              v-if="activeTab > 1"
              @click="activeTab = 1"
              >Zurück</a
            >
            <a
              class="button-tertiary icon-envelope-important"
              v-if="activeTab == 1 && false"
              :disabled="itemsToComplete.quantity !== itemsToComplete.count"
              @click="showSendReminder()"
              >Erinnerung schicken</a
            >
            <a
              class="button-tertiary icon-reset"
              v-if="activeTab == 1"
              @click="showResetDocuments()"
              >Unterlagen zurücksetzen</a
            >
            <a
              class="button-text"
              v-if="activeTab == 1 && false"
              @click="downloadAllDocuments()"
              >Alle Unterlagen herunterladen</a
            >
            <a
              class="button-primary"
              v-if="activeTab == 1"
              @click="savecustomer()"
              >Speichern</a
            >
          </div>
        </div>
      </header>

      <div v-if="activeTab == 1">
        <div class="dashboard-content">
          <div class="grid-32 4-4-4">
            <!-- FELDER -->
            <div class="card">
              <div class="card-header">
                <h3>Kundendaten</h3>
              </div>
              <form class="card-form">
                <div class="form-field">
                  <label>Anrede</label>
                  <select name="anrede" v-model="kunde.Anrede">
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                  </select>
                </div>
                <div class="form-field">
                  <label>Vorname*</label>
                  <input type="text" name="vorname" v-model="kunde.Vorname" />
                </div>
                <div class="form-field">
                  <label>Nachname*</label>
                  <input type="text" name="nachname" v-model="kunde.Nachname" />
                </div>
                <div class="form-field">
                  <v-switch
                    v-model="kunde.hasPartner"
                    :label="`Hat einen Partner`"
                  ></v-switch>
                </div>
                <div class="form-field" v-if="kunde.hasPartner">
                  <label>Anrede Partner</label>
                  <select name="anrede" v-model="kunde.Anrede_Partner">
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                  </select>
                </div>
                <div class="form-field" v-if="kunde.hasPartner">
                  <label>Vorname Partner</label>
                  <input
                    type="text"
                    name="vorname Partner"
                    v-model="kunde.Vorname_Partner"
                  />
                </div>
                <div class="form-field" v-if="kunde.hasPartner">
                  <label>Nachname Partner</label>
                  <input
                    type="text"
                    name="nachname"
                    v-model="kunde.Nachname_Partner"
                  />
                </div>
                <div class="grid-12_10-2">
                  <div class="form-field">
                    <label>Straße</label>
                    <input type="text" name="strasse" v-model="kunde.Strasse" />
                  </div>
                  <div class="form-field">
                    <label>Hausnr.</label>
                    <input
                      type="text"
                      name="hausnr"
                      v-model="kunde.Hausnummer"
                    />
                  </div>
                </div>
                <div class="grid-12_4-8">
                  <div class="form-field">
                    <label>PLZ</label>
                    <input type="text" name="plz" v-model="kunde.Plz" />
                  </div>
                  <div class="form-field">
                    <label>Ort</label>
                    <input type="text" name="ort" v-model="kunde.Ort" />
                  </div>
                </div>
                <div class="form-field">
                  <label>Telefon</label>
                  <input type="tel" name="telefon" v-model="kunde.Telefon" />
                </div>
                <div class="form-field">
                  <label>Mobil</label>
                  <input type="tel" name="mobil" v-model="kunde.Mobil" />
                </div>
                <div class="form-field">
                  <label for="email">E-Mail*</label>
                  <input
                    type="email"
                    name="email"
                    v-model="kunde.Email"
                    disabled
                  />
                </div>
                <div class="form-field">
                  <v-switch
                    name="useonedrivesync"
                    v-model="kunde.AuszahlungAktiviert"
                    :label="`Auszahlung aktivieren`"
                  ></v-switch>
                </div>

                <div class="form-field">
                  <v-switch
                    name="useonedrivesync"
                    v-model="kunde.active"
                    :label="`Aktiv`"
                  ></v-switch>
                </div>

                <div class="form-field" v-if="$store.state.user.uid == 'zdv2DrQPKhYhJtCWgqQVdPopQbh2'">
                  <label for="uid">UID</label>
                  <input type="text" name="uid" v-model="kunde.uid" disabled />
                </div>
              </form>
            </div>

            <!-- VERTRIEBSPARTNER -->
            <div class="card">
              <div class="card-header">
                <h3>Vertriebspartner auswählen</h3>
              </div>
              <div class="action-list" :key="vpKey">
                <employee
                  v-for="vp in getSalesPartners()"
                  :isSelected="vp.uid === kunde.Vertriebspartner"
                  @selected="
                    (vp) => {
                      kunde.Vertriebspartner = vp.uid;
                      vpKey++;
                    }
                  "
                  :employee="vp"
                  :key="vp.uid"
                  :radio="true"
                />
              </div>
            </div>

            <!-- UNTERLAGEN -->
            <div class="block-action">
              <div class="action-header">
                <h3>Erforderliche Unterlagen</h3>

                <div class="header-progress">
                  Vollständigkeit:
                  <span
                    :class="[
                      'tag',
                      itemsToComplete.quantity == itemsToComplete.count
                        ? 'complete'
                        : 'progress',
                    ]"
                    >{{ itemsToComplete.quantity }}/{{
                      itemsToComplete.count
                    }}</span
                  >
                </div>
              </div>

              <div class="action-list">
                <unterlagenKategorie
                  v-for="kat in kunde.Kategorien"
                  :zeigeFortschritt="true"
                  :kategorie="kat"
                  :id="kat.id"
                  @itemClicked="activeTab = kat.id"
                  v-bind:key="kat.id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-content">
        <div class="grid-32">
          <div class="block-action checklist" v-if="activeTab > 1">
            <div class="button-group">
              <a class="button-secondary" @click="editmode = !editmode">{{
                editmode
                  ? "Dokumentenliste nicht mehr bearbeiten"
                  : "Dokumentenliste bearbeiten"
              }}</a>
              <a
                class="button-secondary"
                @click="showAddCustomDocument"
                v-if="editmode === true && activeTab !== 4"
                >Eigenes Dokument hinzufügen
              </a>
              <a
                class="button-secondary"
                @click="_downloadGesundheitsfragen"
                v-if="editmode === false && activeTab === 4"
                >Fragen herunterladen
              </a>
              <a
                class="button-secondary"
                @click="enableAllQuestions"
                v-if="editmode === true && activeTab === 4"
                >Alle Gesundheitsfragen auswählen
              </a>
            </div>
          </div>

          <template v-for="kat in kunde.Kategorien">
            <div :key="kat.id" v-if="activeTab == kat.id">
              <div class="grid-32" :key="kat.id">
                <unterlagenKategorieUeberschrift
                  :categoryName="kat.title"
                  :kategorieUeberschrift="ueberschrift"
                  v-for="ueberschrift in [...kat.ueberschriften].sort(
                    (c) => c.order
                  )"
                  :key="ueberschrift.id"
                  :editmode="editmode"
                  @itemDeclined="itemDeclined"
                  :customer="kunde"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>{{ msg }}</h1>
    </div>
  </div>
</template>


<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

import employee from "@/components/employee";

import unterlagenKategorie from "@/components/unterlagenKategorie";
import unterlagenKategorieUeberschrift from "@/components/unterlagenKategorieUeberschrift";
import customDialog from "@/components/customDialog";

import { firestore, storage, functions } from "@/plugins/firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  doc,
  setDoc,
} from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";

import { ref, uploadBytes, putString, getDownloadURL } from "@firebase/storage";

export default {
  name: "ProfilKunde",
  mixins: [routingFunctionsMixin, firestoreFunctionsMixin],

  components: {
    unterlagenKategorie,
    employee,
    unterlagenKategorieUeberschrift,
    customDialog,
  },
  data() {
    return {
      kunde: null,
      msg: null,
      activeTab: 1,
      vpKey: 0,
      editmode: false,
      showCustomDocumentDialog: false,
      newDocument: {
        title: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async enableAllQuestions() {
      let cat = this.kunde.Kategorien.find((cat) => cat.id === 4);

      for (let headline of cat.ueberschriften) {
        for (let item of headline.items) {
          item.toComplete = true;
        }
      }
    },
    async _downloadGesundheitsfragen() {
      this.isLoading = true;

      try {
        const downloadGesundheitsfragen = httpsCallable(
          functions,
          "downloadGesundheitsfragen"
        );
        const data = await downloadGesundheitsfragen({
          accessToken: this.$store.state.user.accessToken,
          customerDocId: this.kunde.uid,
        });

        if (data.data.error != "internal error") {
          var link = document.createElement("a");
          link.innerHTML = "Download PDF file";
          link.download = `Gesundheitsfragen ${this.kunde.Vorname} ${this.kunde.Nachname}.pdf`;
          link.href = data.data;
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    getSalesPartners() {
      let result = [];

      let allSalesPartner = this.$store.state.allSalesPartners;
      let filteredSalesPartners = allSalesPartner.filter((s) =>
        this.$store.state.employeeprofile.salespartnersIWorkFor.includes(s.uid)
      );
      let x = filteredSalesPartners.sort((a, b) => {
        if (a.uid === this.$store.state.user.uid) {
          return -2;
        }
        return a.Vorname.localeCompare(b.Vorname);
      });

      for (let e of filteredSalesPartners) {
        result.push(e);
      }

      return result;
    },
    showSendReminder() {},
    itemDeclined() {
      this.savecustomer();
    },
    getKatByID(id) {
      let ret = this.kunde.Kategorien.find((kat) => kat.id == id);
      return ret;
    },
    addCustomDocument() {
      let customHeadlineId = 999;
      let actualCategory = this.getKatByID(this.activeTab);

      let ueberschrift = {
        id: customHeadlineId,
        title: " - Zusätzliches - ",
        order: -1,
        items: [],
      };

      let searchedHeadline = actualCategory.ueberschriften.find(
        (c) => c.id === customHeadlineId
      );
      if (!searchedHeadline) {
        actualCategory.ueberschriften.push(ueberschrift);
      }

      let customHeadline = actualCategory.ueberschriften.find(
        (c) => c.id === customHeadlineId
      );
      customHeadline.items.push({
        order: -1,
        questType: "documentUpload",
        title: this.newDocument.title,
        toComplete: true,
        rememberDaily: true,
      });

      this.newDocument.title = "";

      this.showCustomDocumentDialog = false;
    },
    showAddCustomDocument() {
      this.showCustomDocumentDialog = true;
      this.$nextTick(() => {
        const docTitleInput = this.$refs.doctitle;
        docTitleInput.focus;
      });
    },
    async getFileUrl(customerId, fileName) {
      const pathReference = ref(
        storage,
        "FlorianThoms/Documents/" + customerId + "/" + fileName
      );
      let url = "";
      try {
        url = await getDownloadURL(pathReference);
      } catch (error) {}

      return url;
    },
    resetDocuments() {
      this.kunde.Kategorien = JSON.parse(
        JSON.stringify(this.$store.state.allCategories)
      );
      console.log(this.kunde.Kategorien);
      this.kunde.AuszahlungAktiviert = false;
      this.kunde.auszahlungsinfoGesehen = false;
    },
    showResetDocuments() {
      this.$refs.cstmdlg.showDialog(
        "Kundendokumente zurücksetzen",
        `Möchten Sie die Dokumente des Kunden wirklich komplett zurücksetzen? 
        Danach müssen Sie alle Dokumente welche Sie vom Kunden benötigen erneut angeben. 
        Der Kunde muss diese wieder hochladen.`,
        {
          text: "Ja",
          color: "primary",
          fnc: () => {
            this.resetDocuments();
          },
        },
        {
          text: "Abbruch",
          color: "primary",
          fnc: () => {},
        },
        true
      );
    },
    async downloadAllDocuments() {
      const downloadAllCustomerDocuments = httpsCallable(
        functions,
        "downloadAllCustomerDocuments"
      );
      const data = await downloadAllCustomerDocuments({
        accessToken: this.$store.state.user.accessToken,
        customerDocId: this.kunde.uid,
      });

      // Insert a link that allows the user to download the PDF file
      var link = document.createElement("a");
      link.innerHTML = "Download PDF file";
      link.download = "file.pdf";
      link.href = "data:application/octet-stream;base64," + data.data.base64;
      document.body.appendChild(link);
      link.click();

      return;
    },
    async savecustomer() {
      let _collection = collection(firestore, "customerprofile/");
      let _doc = doc(_collection, this.kunde.uid);

      try {
        await setDoc(_doc, this.kunde);
        this.$store.dispatch("showSnackbar", {
          text: "Erfolgreich gespeichert",
          color: "green",
        });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("showSnackbar", {
          text: "Speichern fehlgeschlagen",
          color: "red",
        });
      }
    },
  },
  computed: {
    itemsToComplete() {
      let ret = {
        quantity: 0,
        count: 0,
      };

      let categories = this.kunde.Kategorien;
      for (let c = 0; c < categories.length; c++) {
        const category = categories[c];
        for (let u = 0; u < category.ueberschriften.length; u++) {
          const ueberschrift = category.ueberschriften[u];
          for (let i = 0; i < ueberschrift.items.length; i++) {
            const item = ueberschrift.items[i];

            if (item.toComplete === true) {
              ret.count++;
              if (item.erledigt === true) {
                ret.quantity++;
              }
            }
          }
        }
      }

      return ret;
    },
  },
  async mounted() {
    if (!this.$store.state.user) {
      this.$router.replace({ name: "Login" });
      return;
    }

    let uid = this.$route.params.uid;

    if (uid) {
      let kunde = this.$store.state.myCustomers.find((k) => k.uid == uid);
      if (kunde) {
        this.kunde = kunde;
      } else {
        this.msg = "Sie sind nicht berechtigt die Daten des Kunden einzusehen";
      }
    } else {
      this.$router.replace({ name: "EmployeesOverview" });
    }
  },
};
</script>