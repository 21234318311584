<template>
  <div>
    <div class="text-center">
      <v-dialog persistent v-model="showCustomDocumentDialog" width="700">
        <v-card>
          <v-card-title
            class="text-h5 lighten-2"
            style="background-color: #f4f9fe"
          >
            Neues Dokument hinzufügen
          </v-card-title>

          <v-card-text>
            <form class="card-form">
              <div class="form-field" style="padding-top: 20px">
                <label id="doctitle">Dokumententitel</label>
                <input
                  ref="doctitle"
                  type="text"
                  name="doctitle"
                  v-model="newDocument.title"
                />
              </div>
            </form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="addCustomDocument">
              Hinzufügen
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                newDocument.title = '';
                showCustomDocumentDialog = false;
              "
            >
              Abbruch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>
    <header class="dashboard-header">
      <div class="header-headline">
        <h1 v-if="activeTab == 1">Kunden anlegen</h1>
        <div v-if="activeTab >= 2" class="headline-tag">
          Erforderliche Unterlagen
        </div>
        <h1 v-if="activeTab >= 2">{{ getKatByID(activeTab).title }}</h1>

        <div class="button-group">
          <a
            class="button-tertiary icon-close"
            v-if="activeTab == 1"
            @click="gotoByName('DBBackend')"
            >Abbrechen</a
          >
          <a
            class="button-tertiary icon-back"
            v-if="activeTab > 1"
            @click="activeTab = 1"
            >Zurück</a
          >
          <a
            id="btn-save"
            class="button-primary"
            v-if="activeTab == 1"
            @click="registerNewCustomer()"
            >Speichern</a
          >
        </div>
      </div>
    </header>

    <div v-if="activeTab == 1">
      <div class="dashboard-content">
        <div class="grid-32 4-4-4">
          <!-- FELDER -->
          <div class="card">
            <div class="card-header">
              <h3>Kundendaten</h3>
            </div>
            <form class="card-form">
              <div class="form-field">
                <label>Anrede</label>
                <select name="anrede" v-model="newCustomer.Anrede">
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                </select>
              </div>
              <div class="form-field">
                <label>Vorname*</label>
                <input
                  type="text"
                  name="vorname"
                  v-model="newCustomer.Vorname"
                />
              </div>
              <div class="form-field">
                <label>Nachname*</label>
                <input
                  type="text"
                  name="nachname"
                  v-model="newCustomer.Nachname"
                />
              </div>
              <div class="grid-12_10-2">
                <div class="form-field">
                  <label>Straße</label>
                  <input
                    type="text"
                    name="strasse"
                    v-model="newCustomer.Strasse"
                  />
                </div>
                <div class="form-field">
                  <label>Hausnr.</label>
                  <input
                    type="text"
                    name="hausnr"
                    v-model="newCustomer.Hausnummer"
                  />
                </div>
              </div>
              <div class="grid-12_4-8">
                <div class="form-field">
                  <label>PLZ</label>
                  <input type="text" name="plz" v-model="newCustomer.Plz" />
                </div>
                <div class="form-field">
                  <label>Ort</label>
                  <input type="text" name="ort" v-model="newCustomer.Ort" />
                </div>
              </div>
              <div class="form-field">
                <label>Telefon</label>
                <input
                  type="tel"
                  name="telefon"
                  v-model="newCustomer.Telefon"
                />
              </div>
              <div class="form-field">
                <label>Mobil</label>
                <input type="tel" name="mobil" v-model="newCustomer.Mobil" />
              </div>
              <div class="form-field">
                <label for="email">E-Mail*</label>
                <input type="email" name="email" v-model="newCustomer.Email" />
              </div>
            </form>
          </div>

          <!-- VERTRIEBSPARTNER -->
          <div class="card">
            <div class="card-header">
              <h3>Vertriebspartner auswählen</h3>
            </div>
            <div class="action-list" :key="vpKey">
              <employee
                v-for="sp in $store.state.allSalesPartners.filter((s) =>
                  $store.state.employeeprofile.salespartnersIWorkFor.includes(
                    s.uid
                  )
                )"
                :isSelected="sp.uid === newCustomer.Vertriebspartner"
                @selected="
                  (sp) => {
                    newCustomer.Vertriebspartner = sp.uid;
                    vpKey++;
                  }
                "
                :employee="sp"
                :key="sp.uid"
                :radio="true"
              />
            </div>
          </div>

          <!-- UNTERLAGEN -->
          <div class="card">
            <div class="card-header">
              <h3>Erforderliche Unterlagen</h3>
              <div class="header-progress">
                <span class="tag">{{ itemsToComplete }}</span>
              </div>
            </div>

            <div class="action-list">
              <unterlagenKategorie
                :kategorie="kat"
                :id="kat.id"
                @itemClicked="activeTab = kat.id"
                v-for="kat in newCustomer.kategories"
                v-bind:key="kat.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="kat in newCustomer.kategories" :key="kat.id">
      <div v-if="activeTab == kat.id">
        <div class="dashboard-content">
          <div class="grid-32">
            <div class="block-action checklist" v-if="activeTab !== 4">
              <div class="button-group">
                <a class="button-secondary" @click="showAddCustomDocument"
                  >Eigenes Dokument hinzufügen
                </a>
              </div>
            </div>

            <unterlagenKategorieUeberschrift
              :kategorieUeberschrift="ueberschrift"
              v-for="ueberschrift in [...kat.ueberschriften].sort(
                (c) => c.order
              )"
              :key="ueberschrift.id"
              :editmode="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import customFunctionsMixin from "@/mixins/customFunctions.js";

import { functions } from "@/plugins/firebase";
import { httpsCallable } from "@firebase/functions";

import unterlagenKategorie from "@/components/unterlagenKategorie";
import unterlagenKategorieUeberschrift from "@/components/unterlagenKategorieUeberschrift";

import employee from "@/components/employee";

export default {
  name: "NeuerKunde",
  mixins: [
    routingFunctionsMixin,
    firestoreFunctionsMixin,
    customFunctionsMixin,
  ],
  components: {
    unterlagenKategorie,
    unterlagenKategorieUeberschrift,
    employee,
  },
  data() {
    return {
      valid: false,
      newCustomer: {
        kategories: [],
        Anrede: "Frau"
      },
      activeTab: 1,
      vpKey: 0,
      isLoading: false,
      showCustomDocumentDialog: false,
      newDocument: {
        title: "",
      },
    };
  },
  computed: {
    itemsToComplete() {
      let toCompleteSum = 0;
      for (let i = 0; i < this.newCustomer.kategories.length; i++) {
        const kat = this.newCustomer.kategories[i];
        for (let a = 0; a < kat.ueberschriften.length; a++) {
          const ueberschrift = kat.ueberschriften[a];
          for (let z = 0; z < ueberschrift.items.length; z++) {
            const item = ueberschrift.items[z];
            if (item.toComplete) {
              toCompleteSum++;
            }
          }
        }
      }
      return toCompleteSum;
    },
  },
  methods: {
    addCustomDocument() {
      let customHeadlineId = 999;
      let actualCategory = this.getKatByID(this.activeTab);

      let ueberschrift = {
        id: customHeadlineId,
        title: " - Zusätzliches - ",
        order: -1,
        items: [],
      };

      let searchedHeadline = actualCategory.ueberschriften.find(
        (c) => c.id === customHeadlineId
      );
      if (!searchedHeadline) {
        actualCategory.ueberschriften.push(ueberschrift);
      }

      let customHeadline = actualCategory.ueberschriften.find(
        (c) => c.id === customHeadlineId
      );
      customHeadline.items.push({
        order: -1,
        questType: "documentUpload",
        title: this.newDocument.title,
        toComplete: true,
        rememberDaily: true,
      });

      this.newDocument.title = "";

      this.showCustomDocumentDialog = false;
    },
    showAddCustomDocument() {
      this.showCustomDocumentDialog = true;
      this.$nextTick(() => {
        console.log(this.$refs);
        const docTitleInput = this.$refs.doctitle;
        docTitleInput.focus;
      });
    },
    getKatByID(id) {
      let ret = this.newCustomer.kategories.find((kat) => kat.id == id);
      return ret;
    },

    validateForm() {
      let requiredFields = ["vp", "vorname", "nachname", "email"];
      let validations = {
        vp: {
          func: () => {
            let prop = this.getValTrimmedOrDefault(
              this.newCustomer.Vertriebspartner,
              ""
            );
            return prop.length > 0;
          },
          errorMsg: "Bitte wählen Sie einen Vertriebspartner aus",
        },
        vorname: {
          func: () => {
            let minLength = 1;
            let prop = this.getValTrimmedOrDefault(
              this.newCustomer.Vorname,
              ""
            );
            return prop.length >= minLength;
          },
          errorMsg: "Vorname muss mindestens 1 Zeichen lang sein",
        },
        nachname: {
          func: () => {
            let minLength = 1;
            let prop = this.getValTrimmedOrDefault(
              this.newCustomer.Nachname,
              ""
            );
            return prop.length >= minLength;
          },
          errorMsg: "Nachname muss mindestens 1 Zeichen lang sein",
        },
        email: {
          func: () => {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              this.getValTrimmedOrDefault(this.newCustomer.Email, "")
            );
          },
          errorMsg: "Keine valide Email-Adresse angegeben",
        },
      };

      for (let i = 0; i < requiredFields.length; i++) {
        const fieldName = requiredFields[i];

        if (validations[fieldName] !== undefined) {
          if (!validations[fieldName].func()) {
            this.$store.dispatch("showSnackbar", {
              text: validations[fieldName].errorMsg,
              color: "red",
            });
            console.log(validations[fieldName].errorMsg);
            return false;
          }
        }
      }
      return true;
    },
    async registerNewCustomer() {
      if (this.validateForm()) {
        this.isLoading = true;
        const createCustomer = httpsCallable(functions, "createCustomer");
        const data = await createCustomer({
          newCustomer: this.newCustomer,
          accessToken: this.$store.state.user.accessToken,
        });

        if (data.data.code === 0) {
          await this.$store.dispatch("loadMyCustomers", { reload: true });
          this.$store.dispatch("showSnackbar", {
            text: `Der Kunde '${this.newCustomer.Vorname} ${this.newCustomer.Nachname}' wurde erfolgreich angelegt`,
            color: "green",
          });

          this.$router.push({ name: "DBBackend" });
        } else {
          this.$store.dispatch("showSnackbar", {
            text: data.data.msg,
            color: "red",
          });
        }
      }
      this.isLoading = false;
    },
  },
  mounted: async function () {
    if (!this.$store.state.user) {
      this.$router.push({ name: "Login" });
      return;
    }

    if (
      this.$store.state.employeeprofile.salespartnersIWorkFor.length === 0 &&
      !this.$store.state.employeeprofile.Rollen.includes("Vertriebspartner")
    ) {
      this.$router.push({ name: "Profil" });
      this.$store.dispatch("showSnackbar", {
        text: `Du Du arbeitest aktuell für keinen Vertriebspartner und kannst deshalb keinen Kunden anlegen. Du wirst nun zu deinem Profil weitergeleitet.`,
        color: "red",
      });
    }

    if (this.$store.state.employeeprofile.salespartnersIWorkFor.length > 0) {
      this.newCustomer.Vertriebspartner =
        this.$store.state.employeeprofile.salespartnersIWorkFor[0];
    }

    if (this.$store.state.employeeprofile.Rollen.includes("Vertriebspartner")) {
      this.newCustomer.Vertriebspartner = this.$store.state.employeeprofile.uid;
    }

    this.newCustomer.kategories = JSON.parse(
      JSON.stringify(this.$store.state.allCategories)
    );
  },
};
</script>