<template>
  <div style="width: 100%">
    <loading
      :active="isLoading"
      :color="'#78AEF5'"
      :can-cancel="false"
      :is-full-page="true"
    />
    <v-dialog persistent v-model="showDialog" width="700">
      <v-card>
        <v-card-title
          class="text-h6 lighten-2"
          style="background-color: #f4f9fe"
        >
          Datei neu anfordern
        </v-card-title>
        <br />
        <v-card-text>
          <p style="font-size: 20px">
            Mit welchem Grund soll die Datei '{{ fileToDecline.name }}' erneut
            angefordert werden?
          </p>
          <input type="text" v-model="declineText" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="declineFile">Ablehnen</v-btn>
          <v-btn color="danger" text @click="abort">Abbruch</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      @click="itemClicked()"
      :class="[
        'item-title',
        editmode === true ? 'radio' : '',
        erledigt === true && editmode === false ? 'ueberschrifterledigt' : '',
      ]"
    >
      <span>{{ kategorieUeberschriftItem.title }}</span>
      <span style="color: red">{{
        mitVorlage === true ? "&nbsp;(inkl. Vorlage)" : ""
      }}</span>

      <span style="color: red"
        >{{
          erledigt === true && editmode === true
            ? "&nbsp;(bereits hochgeladen)"
            : ""
        }}
      </span>
    </div>
    <div v-if="kategorieUeberschriftItem.erledigt && !editmode">
      <div v-if="kategorieUeberschriftItem.answers">
        <span>Antwort: </span
        ><span>{{ kategorieUeberschriftItem.answer.title }}</span
        ><br />
        <div v-if="kategorieUeberschriftItem.answer.subquestion">
          <span>
            {{ kategorieUeberschriftItem.answer.subquestion.title }}<br />
          </span>
          <span>Antwort: </span
          ><span>{{
            kategorieUeberschriftItem.answer.subquestion.answer
          }}</span>
        </div>
      </div>

      <div
        v-if="
          !kategorieUeberschriftItem.answers && kategorieUeberschriftItem.answer
        "
      >
        <span>Antwort: </span
        ><span>{{ kategorieUeberschriftItem.answer }}</span>
      </div>
    </div>

    <div v-for="file in kategorieUeberschriftItem.Files" :key="file.id">
      <div class="item-content">
        <div class="content-upload">
          <button
            :class="[
              file.declined ? '' : 'button-secondary',
              file.declined ? '' : 'icon-download',
              file.declined ? 'rejected' : '',
              'button-green',
            ]"
            @click="downloadFile(file)"
          >
            {{ file.name }} herunterladen</button
          ><br />

          <button
            :class="['button-secondary', 'icon-close', 'button-red']"
            v-if="!file.declined"
            @click="showDeclineFile(file)"
          >
            {{ file.name }} ablehnen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { httpsCallable } from "@firebase/functions";
import { getDownloadURL, ref } from "@firebase/storage";
import { functions, storage } from "../plugins/firebase";

export default {
  name: "UnterlagenKategorieUeberschriftItem",
  props: [
    "kategorieUeberschriftItem",
    "id",
    "editmode",
    "erledigt",
    "mitVorlage",
    "showDownload",
    "customer",
  ],
  data: function () {
    return {
      toComplete: false,
      showDialog: false,
      fileToDecline: { name: "" },
      defaultText:
        "Die Aufnahme ist leider zu unscharf, bitte fotografiere die Datei erneut.",
      declineText: "",
      isLoading: false,
    };
  },
  methods: {
    downloadPDF(url) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(myBlob);
          link.download = "yourname.pdf";
          link.click();
        }
      };
      xhr.send();
    },
    async downloadFile(file) {
      //console.log("useragent", navigator.userAgent);

      let storagePath = `FlorianThoms/Documents/${this.customer.uid}/${file.name}`;
      const storageRef = ref(storage, storagePath);
      let url = await getDownloadURL(storageRef);

      if (navigator.userAgent == "kundenportal_florianthoms_app") {
        //this.downloadPDF(url)
        //console.log(url);
      } else {
        /*
        var link = document.createElement("a");
        link.download = file.name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        */
        
        window.open(url);
      }
    },
    abort() {
      this.declineText = this.defaultText;
      this.showDialog = false;
    },
    showDeclineFile(file) {
      this.declineText = this.defaultText;

      this.fileToDecline = file;
      this.showDialog = true;
    },
    async declineFile() {
      const declineFile = httpsCallable(functions, "declineFile");
      const data = await declineFile({
        accessToken: this.$store.state.user.accessToken,
        headline: this.kategorieUeberschriftItem,
        file: this.fileToDecline,
        customer: this.customer,
        declineText: this.declineText,
      });

      this.kategorieUeberschriftItem.erledigt = false;
      this.fileToDecline.declined = true;
      this.fileToDecline.declineMessage = this.declineText;
      this.$emit("itemDeclined");
      this.showDialog = false;
    },
    itemClicked() {
      if (this.erledigt === true && this.editmode === true) {
        this.$store.dispatch("showSnackbar", {
          text: "Ein bereits hochgeladenes Dokument oder eine bereits beantwortete Frage kann nicht mehr entfernt werden",
          color: "red",
        });
        return;
      }

      this.$emit("itemClicked");
    },
  },
  mounted: async function () {},
};
</script>