import {initializeApp} from '@firebase/app';
import {connectStorageEmulator, getStorage} from "@firebase/storage";
import {getFirestore, connectFirestoreEmulator} from '@firebase/firestore';
import {getFunctions, connectFunctionsEmulator } from "@firebase/functions"
import {getAuth, connectAuthEmulator} from "@firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBkLDwjwgv7ei1tlD_Ba6Oo3-iR32t6qYg",
    authDomain: "kundenportal-ft.firebaseapp.com",
    projectId: "kundenportal-ft",
    storageBucket: "kundenportal-ft.appspot.com",
    messagingSenderId: "149302497392",
    appId: "1:149302497392:web:f854833786f6ed0ca9e4b5",
    measurementId: "G-QDPYJMEEME"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west3")

const auth = getAuth();
auth.languageCode = "de";

if(location.hostname == "localhost") {
    
    
    connectFunctionsEmulator(functions, "localhost", 5001);
    /*
    connectFirestoreEmulator(firestore, "localhost", 8081);
    connectStorageEmulator(storage, "localhost", 9199)
    connectAuthEmulator(auth, "http://localhost:9099");
    */
}

export {
    firestore,
    functions,
    auth,
    storage,
}