<template>
  <div class="block-action checklist">
    <div class="action-header">
      <h3>{{ kategorieUeberschrift.title }}</h3>
      <div class="header-progress">
        <span class="tag"
          >{{ itemsToComplete }}/{{ kategorieUeberschrift.items.length }}</span
        >
      </div>
    </div>
    <div class="action-list" v-if="getItems(kategorieUeberschrift).length > 0">
      
      <div :class="['action-item', item.toComplete && editmode === true ? 'check' : '',]"
        v-for="item in getItems(kategorieUeberschrift)"
        :key="item.id"
      >
        <unterlagenKategorieUeberschriftItem
          @itemClicked="itemClicked(item)"
          :kategorieUeberschriftItem="item"
          :editmode="editmode"
          :erledigt="item.erledigt"
          :mitVorlage="item.Vorlage && item.Vorlage.length > 0"
          @itemDeclined="itemDeclined"
          :customer="customer"
        />
      </div>
    </div>
    <div class="action-list" v-else>
      <div class="action-item empty">
        <div class="item-title">
          <span>Keine Unterlagen ausgewählt</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import unterlagenKategorieUeberschriftItem from "@/components/unterlagenKategorieUeberschriftItem";

export default {
  name: "UnterlagenKategorieUeberschrift",
  components: {
    unterlagenKategorieUeberschriftItem,
  },
  props: ["kategorieUeberschrift", "id", "editmode", "customer"],
  data: function () {
    return {};
  },
  computed: {
    itemsToComplete() {
      let toCompleteSum = 0;
      for (let i = 0; i < this.kategorieUeberschrift.items.length; i++) {
        const item = this.kategorieUeberschrift.items[i];
        if (item.toComplete) {
          toCompleteSum++;
        }
      }
      return toCompleteSum;
    },
  },
  methods: {
    itemDeclined(){
      this.$emit("itemDeclined");
    },
    getItems(ueberschrift) {
      let ret = [];
      if (this.editmode === true) {
        ret = ueberschrift.items;
      } else {
        for(let item of ueberschrift.items){
          if(item.toComplete === true){
            ret.push(item);
          }
        }
      }

      
      return ret;
    },
    itemClicked(item) {
      if (this.editmode === true) {
        item.toComplete = !item.toComplete;
      }
    },
  },
  mounted: async function () {},
};
</script>