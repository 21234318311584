export default {
    data() {
        return {


        }
    },
    created: function () {

    },
    methods: {
        getValTrimmedOrDefault(val, def) {
            if (this.isEmpty(val)) {
                return def;
            }
            return val.trim();
        },

        isEmpty(val) {
            return val === undefined || val === null || val.trim() === "";
        },

    }
};