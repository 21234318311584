<template>
    <div>
        <div>
            <loading :active="isLoading" :color="'#78AEF5'" :can-cancel="false" :is-full-page="true" />
        </div>
    </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

import { httpsCallable } from "@firebase/functions";
import { functions } from "@/plugins/firebase";


export default {
    name: "CallbackOneDrive",
    mixins: [],
    components: {},

    data() {
        return {
            isLoading: true
        }
    },
    methods: {


    },
    computed: {

    },
    async mounted() {
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push({ name: "Login" });
            return;
        }


        if (this.$route.query && this.$route.query.code) {

            const getPersonalOneDriveToken = httpsCallable(functions, "getPersonalOneDriveToken");
            const data = await getPersonalOneDriveToken({
                accessToken: this.$store.state.user.accessToken,
                authcode: this.$route.query.code.trim(),
            });
            //console.log(data);
            this.$router.push({ name: "Profil" });

        }

    }



}
</script>

<style lang="scss" scoped>
</style>