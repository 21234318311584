<template>
  <div :key="componentKey" style="width: 100%">
    <v-dialog persistent v-model="showDialogDynTemplate" width="700">
      <v-card>
        <v-card-title
          class="text-h6 lighten-2"
          style="background-color: #f4f9fe"
        >
          Vorlage ausfüllen
        </v-card-title>
        <br />
        <v-card-text v-if="kategorieUeberschriftItem.DynTemplate">
          <div
            class="form-field"
            v-for="field in kategorieUeberschriftItem.DynTemplate.fields"
            :key="field.id"
          >
            <div
              v-if="
                ((field.ifPartner && $store.state.customerprofile.hasPartner) ||
                  !field.ifPartner) &&
                !field.multiple
              "
            >
              <span>{{ field.title }}</span>
              <input
                v-if="field.type == 'String'"
                type="text"
                v-model="templateFields[field.field]"
              />
              <input
                v-if="field.type == 'Number'"
                type="text"
                v-model="templateFields[field.field]"
              />
              <input
                v-if="field.type == 'Date'"
                type="text"
                v-model="templateFields[field.field]"
              />
              <br />
            </div>

            <div v-if="field.multiple" :key="templateKey">
              <br /><br />
              <span
                ><h4>{{ field.title }}</h4></span
              >
              <br />
              <div
                v-for="(tf, index) in templateFields[field.field]"
                :key="tf.id"
              >
                <div v-for="_key in Object.keys(tf)" :key="_key">
                  <span>{{ _key }} {{ index + 1 }}</span>
                  <input
                    v-if="field.type == 'String'"
                    type="text"
                    v-model="templateFields[field.field][index][_key]"
                  />
                </div>
              </div>
              <button
                class="button-primary"
                @click="
                  templateFields[field.field].push(
                    JSON.parse(JSON.stringify(field.objToAdd))
                  );
                  templateKey++;
                "
              >
                Vertrag hinzufügen
              </button>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            class="button-secondary icon-download"
            text
            @click="downloadDynTemplate()"
          >
            Vorlage downloaden
          </button>

          <button
            class="button-secondary icon-close"
            text
            @click="abortDynTemplateDialog"
          >
            Abbruch
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDialogDelete" width="700">
      <v-card>
        <v-card-title
          class="text-h6 lighten-2"
          style="background-color: #f4f9fe"
        >
          Datei löschen
        </v-card-title>
        <br />
        <v-card-text>
          <p v-if="fileToDelete" style="font-size: 20px">
            Möchten Sie die Datei {{ fileToDelete.name }} wirklich löschen?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteFile()">Löschen</v-btn>
          <v-btn color="danger" text @click="abortDeleteDialog">Abbruch</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading
      :active="isLoading"
      :color="'#78AEF5'"
      :can-cancel="false"
      :is-full-page="true"
    />
    <div
      :class="[
        'item-title',
        'radio',
        kategorieUeberschriftItem.erledigt ? 'complete' : '',
      ]"
      @click="elapse"
    >
      {{ kategorieUeberschriftItem.title }}
    </div>
    <div
      v-if="
        kategorieUeberschriftItem.questType === 'documentUpload' &&
        kategorieUeberschriftItem.elapsed === true
      "
      class="item-content"
      style="display: block"
    >
      <div class="content-documents">
        <div
          class="document-item"
          v-if="
            !(
              kategorieUeberschriftItem.Files &&
              kategorieUeberschriftItem.Files.length > 0
            )
          "
        >
          Keine Dokumente vorhanden
        </div>

        <div
          v-for="file in kategorieUeberschriftItem.Files"
          :class="['document-item', file.declined === true ? 'rejected' : '']"
          :key="file.id"
        >
          {{ file.name }}
          <div class="item-menu">
            <button class="button-menu icon-show" @click="downloadFile(file)">
              Dokument anzeigen
            </button>
            <button
              class="button-menu icon-delete"
              @click="showDeleteFile(file)"
            >
              Löschen
            </button>
          </div>
          <div class="rejected-info">
            <button class="button-menu icon-info"></button>
            <div class="rejected-text">
              {{ file.declineMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="content-upload">
        <button
          class="button-secondary icon-download"
          v-if="kategorieUeberschriftItem.Vorlage"
          @click="downloadTemplate"
        >
          <span>Vorlage downloaden</span>
        </button>
        <button
          class="button-secondary icon-download"
          v-if="kategorieUeberschriftItem.DynTemplate"
          @click="showDynTemplate"
        >
          <span>Vorlage downloaden</span>
        </button>
        <button
          class="button-secondary icon-camera"
          @click="toggleCamera"
          v-if="false"
        >
          Dokument fotografieren
        </button>
        <button class="button-secondary icon-gallery" @click="chooseFromGalery">
          Aus Galerie auswählen / Foto aufnehmen
        </button>
        <div class="upload-hint">
          <strong>WICHTIG: Achte bitte auf folgende Punkte:</strong> <br />

          <span v-for="index in infoTexts.length" :key="index">
            {{ index }}. {{ infoTexts[index - 1] }}<br />
          </span>
        </div>
      </div>
      <div class="content-menu" :key="componentKey">
        <button class="button-tertiary icon-close" @click="collapse">
          Schließen
        </button>
        <button
          class="button-primary"
          :disabled="!anyChanges()"
          @click="saveItemDocumentUpload"
        >
          Speichern
        </button>
      </div>
    </div>

    <div
      v-if="
        kategorieUeberschriftItem.questType === 'question' &&
        kategorieUeberschriftItem.elapsed === true
      "
      class="item-content"
      style="display: block"
    >
      <div class="" style="min-width: 100px">
        <div
          :class="[
            kategorieUeberschriftItem.type == 'int' ? 'input-small' : '',
            kategorieUeberschriftItem.type == 'bool'
              ? 'radio-inline'
              : 'document-item',
          ]"
        >
          <input
            v-if="
              kategorieUeberschriftItem.type == 'int' ||
              kategorieUeberschriftItem.type == 'string'
            "
            type="text"
            v-model="kategorieUeberschriftItem.answer"
          />

          <div
            v-for="answer in kategorieUeberschriftItem.answers"
            :key="answer.title"
            :class="[
              kategorieUeberschriftItem.type == 'bool' ? 'radio-inline' : '',
            ]"
          >
            <input
              v-if="kategorieUeberschriftItem.type == 'bool'"
              type="radio"
              name="radiogroup"
              :value="answer"
              v-model="kategorieUeberschriftItem.answer"
            />
            <label :for="answer.title">{{ answer.title }}</label>
          </div>
        </div>
        <div
          v-if="
            kategorieUeberschriftItem.answer &&
            kategorieUeberschriftItem.answer.subquestion
          "
        >
          <p>{{ kategorieUeberschriftItem.answer.subquestion.title }}</p>
          <input
            v-if="
              kategorieUeberschriftItem.answer.subquestion.type == 'int' ||
              kategorieUeberschriftItem.answer.subquestion.type == 'string'
            "
            type="text"
            v-model="kategorieUeberschriftItem.answer.subquestion.answer"
          />
        </div>
      </div>

      <div class="content-menu" :key="componentKey">
        <button class="button-tertiary icon-close" @click="collapse">
          Schließen
        </button>
        <button
          class="button-primary"
          :disabled="
            !(
              kategorieUeberschriftItem.answer &&
              (kategorieUeberschriftItem.answer.length > 0 ||
                kategorieUeberschriftItem.answer.title.length > 0)
            )
          "
          @click="saveItemQuestion"
        >
          Speichern
        </button>
      </div>
    </div>

    <v-file-input
      v-show="false"
      :ref="'fileUpload'"
      accept=".jpg,.jpeg,.pdf"
      label="File input"
      @change="(file) => setImage(file)"
    ></v-file-input>
  </div>
</template>


<script>
import { firestore, storage } from "@/plugins/firebase";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
} from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";

import {
  ref,
  uploadBytes,
  putString,
  getDownloadURL,
  deleteObject,
} from "@firebase/storage";
import { functions } from "../plugins/firebase";

export default {
  name: "UnterlagenKategorieUeberschriftItemCustomer",
  props: [
    "kategorieUeberschriftItem",
    "categoryName",
    "categoryHeadline",
    "id",
  ],
  components: {},
  data: function () {
    return {
      toComplete: false,
      componentKey: 0,
      templateKey: 0,
      camera: {
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
      },
      isLoading: false,
      showDialogDelete: false,
      showDialogDynTemplate: false,

      fileToDelete: null,
      templateFields: {},
    };
  },
  methods: {
    dynTemplateValid() {
      let valid = true;

      console.log(this.templateFields);
      for (let s of Object.keys(this.templateFields)) {
        console.log(typeof this.templateFields[s]);

        if ("undefined" === typeof this.templateFields[s]) {
          continue;
        }

        if (typeof this.templateFields[s] == "object") {
          for (let tf of this.templateFields[s]) {
            for (let field of Object.keys(tf)) {
              if (tf[field].trim().length === 0) {
                valid = false;
              }
            }
          }
        } else if (this.templateFields[s].trim().length === 0) {
          valid = false;
        }
      }

      return valid;
    },
    async downloadDynTemplate() {
      if (!this.dynTemplateValid()) {
        this.$store.dispatch("showSnackbar", {
          text: "Sie müssen erst alle Felder ausgefüllt haben, bevor Sie die Vorlage herunterladen können",
          color: "red",
        });
      } else {
        try {
          const getDynTemplatePDF = httpsCallable(
            functions,
            "getDynTemplatePDF"
          );
          const data = await getDynTemplatePDF({
            accessToken: this.$store.state.user.accessToken,
            templateFields: this.templateFields,
            item: this.kategorieUeberschriftItem,
          });

          
          if (data.data.code != -1) {
            var downloadLink = document.createElement("a");
            downloadLink.href = data.data;
            downloadLink.download =
              this.kategorieUeberschriftItem.DynTemplate.filename;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }else{

          }
        } catch (error) {}

        this.showDialogDynTemplate = false;
      }
    },
    showDynTemplate() {
      this.showDialogDynTemplate = true;
    },
    abortDynTemplateDialog() {
      this.showDialogDynTemplate = false;
    },
    async showDeleteFile(file) {
      this.fileToDelete = file;
      this.showDialogDelete = true;
      return;
    },
    async deleteFile() {
      try {
        this.isLoading = true;
        let storagePath = `FlorianThoms/Documents/${this.$store.state.user.uid}/${this.fileToDelete.name}`;
        const storageRef = ref(storage, storagePath);
        await deleteObject(storageRef);
      } catch (err) {
        console.log(err);
      }

      try {
        let fileIndex = this.kategorieUeberschriftItem.Files.indexOf(
          this.fileToDelete
        );
        if (fileIndex >= 0) {
          this.kategorieUeberschriftItem.Files.splice(fileIndex, 1);
          this.componentKey++;
        }
        this.saveItemDocumentUpload();
      } catch (err) {
        console.log(err);
      }

      this.showDialogDelete = false;
      this.isLoading = false;
    },
    async downloadFile(file) {
      let storagePath = `FlorianThoms/Documents/${this.$store.state.user.uid}/${file.name}`;
      const storageRef = ref(storage, storagePath);
      let url = await getDownloadURL(storageRef);

      if (navigator.userAgent == "kundenportal_florianthoms_app") {
        //this.downloadPDF(url)
        //console.log(url);
      } else {
        /*
        var link = document.createElement("a");
        link.download = file.name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        */

        window.open(url);
      }
    },

    abortDeleteDialog() {
      this.fileToDelete = null;
      this.showDialogDelete = false;
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadTemplate() {
      if (this.kategorieUeberschriftItem) {
        let url = await this.getUrl(this.kategorieUeberschriftItem.Vorlage);
        console.log(url);

        this.downloadURI(url, "Vorlage.pdf");
      }
    },
    async getUrl(templateName) {
      const pathReference = ref(
        storage,
        "FlorianThoms/Vorlagen/" + templateName
      );
      let url = "";
      try {
        url = await getDownloadURL(pathReference);
      } catch (error) {}

      return url;
    },

    async saveItemQuestion() {
      this.kategorieUeberschriftItem.erledigt = true;
      this.collapse();

      let _collection = collection(firestore, "customerprofile/");
      let _doc = doc(_collection, this.$store.state.user.uid);

      await setDoc(_doc, this.$store.state.customerprofile);

      this.$store.dispatch("showSnackbar", {
        text: "Erfolgreich gespeichert",
        color: "green",
      });
    },
    async saveItemDocumentUpload(collapse = true) {
      let saveOk = true;
      this.isLoading = true;
      if (
        this.kategorieUeberschriftItem.FilesToAdd &&
        this.kategorieUeberschriftItem.FilesToAdd.length > 0
      ) {
        for (
          let i = 0;
          i < this.kategorieUeberschriftItem.FilesToAdd.length;
          i++
        ) {
          const file = this.kategorieUeberschriftItem.FilesToAdd[i];

          let storagePath =
            "FlorianThoms/Documents/" +
            this.$store.state.user.uid +
            "/" +
            file.name;
          const storageRef = ref(storage, storagePath);
          let snapshot = await uploadBytes(storageRef, file.file);
          /*
          const uploadToOneDrive = httpsCallable(functions, "uploadToOneDrive");
          let response = await uploadToOneDrive({
            file: file,
            accessToken: this.$store.state.user.accessToken,
          });
          */

          //console.log(storagePath);
          delete file.fileBase64;
        }
      }

      delete this.kategorieUeberschriftItem.FilesToAdd;
      for (let i = 0; i < this.kategorieUeberschriftItem.Files.length; i++) {
        let file = this.kategorieUeberschriftItem.Files[i];
        delete file.file;
      }

      if (
        this.kategorieUeberschriftItem.Files &&
        this.kategorieUeberschriftItem.Files.length > 0
      ) {
        this.kategorieUeberschriftItem.erledigt = true;
      } else {
        this.kategorieUeberschriftItem.erledigt = false;
      }

      if (collapse) {
        this.collapse();
      }

      let _collection = collection(firestore, "customerprofile/");
      let _doc = doc(_collection, this.$store.state.user.uid);

      try {
        await setDoc(_doc, this.$store.state.customerprofile);
        this.$store.dispatch("showSnackbar", {
          text: "Erfolgreich gespeichert",
          color: "green",
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch("showSnackbar", {
          text: "Speichern fehlgeschlagen",
          color: "red",
        });
      }
      this.isLoading = false;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    async base64ToFile(data) {
      return new Promise((resolve, reject) => {
        fetch(data)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "test.pdf", {
              type: "application/pdf",
            });
            resolve(file);
          });
      });
    },
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          let base64 = reader.result;
          resolve(base64);
        };
      });
    },
    async setImage(file) {
      var self = this;

      let allowedFileTypes = ["jpg", "jpeg", "pdf"];
      let splitted = file.name.split(".");
      let filetype = splitted[splitted.length - 1].toLowerCase();

      if (!allowedFileTypes.includes(filetype)) {
        this.$store.dispatch("showSnackbar", {
          text: "Ungültiger Dateityp",
          color: "red",
        });
        return;
      }

      let base64 = await this.fileToBase64(file);
      if (!self.kategorieUeberschriftItem.FilesToAdd) {
        self.kategorieUeberschriftItem.FilesToAdd = [];
      }
      if (!self.kategorieUeberschriftItem.Files) {
        self.kategorieUeberschriftItem.Files = [];
      }

      this.isLoading = true;

      let pdfFile;
      if (filetype == "jpg" || filetype == "jpeg") {
        const jpgtopdf = httpsCallable(functions, "jpgtopdf");
        let pdfBase64 = await jpgtopdf({
          base64: base64,
        });
        pdfFile = await this.base64ToFile(pdfBase64.data);
      } else if (filetype == "pdf") {
        pdfFile = file;
      }

      this.isLoading = false;

      let guid = self.uuidv4();
      let smallguid = guid.split("-")[4];
      let newFile = {
        file: pdfFile,
        //fileBase64: pdfBase64.data,
        name: file.name.split(".")[0] + "-" + smallguid + ".pdf",
        guid: guid,
        category: self.categoryName,
        headline: self.categoryHeadline,
        title: self.kategorieUeberschriftItem.title,
      };

      self.kategorieUeberschriftItem.FilesToAdd.push(newFile);
      self.kategorieUeberschriftItem.Files.push(newFile);
      self.componentKey++;

      this.saveItemDocumentUpload(false);
    },
    chooseFromGalery() {
      let fileupload = this.$refs["fileUpload"];
      fileupload.$refs.input.click();
    },
    elapse() {
      this.$emit("collapseAllItems");
      this.kategorieUeberschriftItem.elapsed = true;
      this.componentKey++;
    },
    collapse() {
      this.kategorieUeberschriftItem.elapsed = false;
      this.componentKey++;
    },
    anyChanges() {
      if (
        this.kategorieUeberschriftItem.FilesToAdd &&
        this.kategorieUeberschriftItem.FilesToAdd.length > 0
      ) {
        return true;
      }
      if (
        this.kategorieUeberschriftItem.FilesToRemove &&
        this.kategorieUeberschriftItem.FilesToRemove.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    infoTexts() {
      let result = [];
      result.push(
        "Das Dokument muss gut lesbar sein (scharf und genügend Licht)"
      );
      result.push(
        "Das gesamte Dokument muss erkennbar sein (auch Fuß- und Kopfzeile)"
      );

      if (this.kategorieUeberschriftItem.infoTexts) {
        for (let it of this.kategorieUeberschriftItem.infoTexts.sort(
          (i) => i.order
        )) {
          result.push(it.text);
        }
      }

      return result;
    },
  },
  mounted: async function () {
    if (this.kategorieUeberschriftItem.DynTemplate) {
      for (let field of this.kategorieUeberschriftItem.DynTemplate.fields) {
        if (field.default) {
          if (field.default == "__ARRAY__") {
            this.templateFields[field.field] = [];

            this.templateFields[field.field].push(
              JSON.parse(JSON.stringify(field.objToAdd))
            );
            //this.templateFields[field.field].push(JSON.parse(JSON.stringify(field.objToAdd)));
          } else {
            this.templateFields[field.field] =
              this.$store.state.customerprofile[field.default];
          }
        } else {
          this.templateFields[field.field] = "";
        }
      }
    }
  },
};
</script>