<template>
  <div style="padding: 5em" :key="componentKey">
    <button class="button-primary" @click="save">Speichern</button>
    <button class="button-primary" @click="updateCustomers">
      Kunden updaten
    </button>
    <button class="button-primary" @click="setrd">Remember Daily True</button>

    <div v-for="cat in categories" :key="cat.id">
      <v-row>
        <v-col md="10">
          <div class="form-field">
            <label for="categorytitle">Kategorie</label>
            <input name="categorytitle" type="text" v-model="cat.title" />
          </div>
        </v-col>
        <v-col md="2">
          <div class="form-field">
            <label for="categorytitle">docId</label>
            <input
              name="categorytitle"
              type="text"
              v-model="cat.docId"
              readonly
            />
          </div>
        </v-col>
      </v-row>

      <div v-for="u in cat.ueberschriften" :key="u.id" style="padding: 1em">
        <div class="form-field">
          <label for="ueberschrift">Überschrift</label>
          <input name="ueberschrift" type="text" v-model="u.title" />
        </div>

        <div
          v-for="i in u.items"
          :key="i.key"
          style="padding: 1em; border: 1px solid black; margin-bottom: 1em"
        >
          <div>
            <v-row>
              <v-col md="8">
                <div class="form-field">
                  <label for="title">Title</label>
                  <input name="title" type="text" v-model="i.title" />
                </div>
              </v-col>
              <v-col md="4">
                <div class="form-field">
                  <label for="guid">Guid</label>
                  <input name="guid" type="text" v-model="i.guid" readonly />
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="1">
                <div class="form-field">
                  <label for="order">Order</label>
                  <input name="order" type="text" v-model="i.order" />
                </div>
              </v-col>
              <v-col md="2">
                <div class="form-field">
                  <label for="qtype">Questtype</label>
                  <input name="qtype" type="text" v-model="i.questType" />
                </div>
              </v-col>
               <v-col md="2">
                <div class="form-field">
                  <label for="template">Template</label>
                  <input name="template" type="text" v-model="i.Vorlage" />
                </div>
              </v-col>
                 
               <v-col md="2">
                <div class="form-field">
                  <label for="type">Type</label>
                  <input name="type" type="text" v-model="i.type" />
                </div>
              </v-col>
              <v-col md="2">
                <div class="form-field">
                  <label for="tocomplete">ToComplete</label>
                  <v-switch name="tocomplete" v-model="i.toComplete"></v-switch>
                </div>
              </v-col>
              <v-col md="2">
                <div class="form-field">
                  <label for="tocomplete">Remember daily</label>
                  <v-switch
                    name="tocomplete"
                    v-model="i.rememberDaily"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="1"> Infotexte </v-col>
              <v-col md="1">
                <button class="button-tertiary icon-close" @click="addit(i)">
                  Hinzufügen
                </button>
              </v-col>
            </v-row>

            <v-row v-for="(it, index) in i.infoTexts" :key="it.key">
              <v-col md="1">
                <div class="form-field">
                  <label for="nr">Nr</label>

                  <label name="nr">{{ index + 1 }})</label>
                </div>
              </v-col>
              <v-col md="8">
                <div class="form-field">
                  <label for="infotext">Text</label>
                  <input name="infotext" type="text" v-model="it.text" />
                </div>
              </v-col>
              <v-col md="1">
                <div class="form-field">
                  <label for="itorder">Order</label>
                  <input name="itorder" type="text" v-model="it.order" />
                </div>
              </v-col>
              <v-col md="1">
                <label for="remit">Entfernen</label>

                <button
                  name="remit"
                  class="button-primary"
                  @click="removeit(i, index)"
                >
                  Entfernen
                </button>
              </v-col>
            </v-row>
          </div>
          <div v-if="i.DynTemplate">
            <v-row>
              <v-col md="1"> DynTemplate </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <div class="form-field">
                  <label for="fn">Filename</label>
                  <input
                    name="fn"
                    type="text"
                    v-model="i.DynTemplate.filename"
                  />
                </div>
              </v-col>
              <v-col md="1">
                <div class="form-field">
                  <label for="solomode">Normal-mode</label>
                  <v-switch
                    name="solomode"
                    v-model="i.DynTemplate.normalmode"
                  ></v-switch>
                </div>
              </v-col>
              <v-col md="1">
                <div class="form-field">
                  <label for="solomode">Partner-mode</label>
                  <v-switch
                    name="solomode"
                    v-model="i.DynTemplate.partnermode"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="i.DynTemplate.normalmode">
              <v-col md="12">
                <div class="form-field">
                  <label for="fn">Template</label>
                  <textarea
                    rows="20"
                    name="guid"
                    type="text"
                    v-model="i.DynTemplate.template"
                    style="width: 100%; border: 2px solid red"
                  >
                  </textarea>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="i.DynTemplate.partnermode">
              <v-col md="12">
                <div class="form-field">
                  <label for="fn">Template Solo</label>
                  <textarea
                    rows="20"
                    name="guid"
                    type="text"
                    v-model="i.DynTemplate.template_solo"
                    style="width: 100%; border: 2px solid red"
                  >
                  </textarea>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="i.DynTemplate.partnermode">
              <v-col md="12">
                <div class="form-field">
                  <label for="fn">Template Partner</label>
                  <textarea
                    rows="20"
                    name="guid"
                    type="text"
                    v-model="i.DynTemplate.template_partner"
                    style="width: 100%; border: 2px solid red"
                  >
                  </textarea>
                </div>
              </v-col>
            </v-row>
          </div>

          <div v-if="i.DynTemplate">
            <v-row>
              <v-col md="1"> Fields </v-col>
            </v-row>

            <v-row v-for="field in i.DynTemplate.fields" :key="field.key">
              <v-col md="2">
                <div class="form-field">
                  <label for="fn">Type</label>
                  <input name="fn" type="text" v-model="field.type" />
                </div>
              </v-col>

              <v-col md="4">
                <div class="form-field">
                  <label for="fn">Title</label>
                  <input name="fn" type="text" v-model="field.title" />
                </div>
              </v-col>

              <v-col md="2">
                <div class="form-field">
                  <label for="fn">Field</label>
                  <input name="fn" type="text" v-model="field.field" />
                </div>
              </v-col>

              <v-col md="2">
                <div class="form-field">
                  <label for="fn">Default</label>
                  <input name="fn" type="text" v-model="field.default" />
                </div>
              </v-col>

                 <v-col md="2">
                <div class="form-field">
                  <label for="ifpartner">Nur anzeigen wenn Partner vorhanden</label>
                  
                     <v-switch
                    name="ifpartner"
                    v-model="field.ifPartner"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import { firestore } from "../../../plugins/firebase";

export default {
  name: "ManageCategories",
  data() {
    return {
      categories: [],
      componentKey: 0,
    };
  },
  methods: {
    setrd() {
      for (let cat of this.categories) {
        for (let headline of cat.ueberschriften) {
          for (let item of headline.items) {
            item.rememberDaily = true;
          }
        }
      }
      this.componentKey++;
    },
    async updateCustomers() {
      let newKatItemsByGuid = new Map();
      for (let cat of this.categories) {
        for (let headline of cat.ueberschriften) {
          for (let item of headline.items) {
            let itemGuid = item.guid;
            newKatItemsByGuid.set(itemGuid, item);
          }
        }
      }

      let customers = [];
      let customersSnapshot = await getDocs(
        query(
          collection(firestore, "customerprofile")
          
        )
      );

      for (let doc of customersSnapshot.docs) {
        let data = { ...doc.data() };
        data.docId = doc.id;
        customers.push(data);
      }

      console.log("Aktive Kunden", customers.length);

      for (let customer of customers) {
        console.log(
          customer.Vorname,
          customer.Nachname,
          customer.docId,
          customer
        );

        let customerCategories = customer.Kategorien;

        //for cat
        for (let cat of customerCategories) {
          //for headline
          for (let headline of cat.ueberschriften) {
            //for item
            for (let customerItem of headline.items) {
              let customerItemGuid = customerItem.guid;
              let newItem = newKatItemsByGuid.get(customerItemGuid);

              if (!newItem) {
                console.log("Error", customer, customerItemGuid, customerItem, newItem);
              } else {
                if (newItem.title) {
                  customerItem.title = newItem.title;
                }

                if (newItem.order) {
                  customerItem.order = newItem.order;
                }

                if (newItem.questType) {
                  customerItem.questType = newItem.questType;
                }

                if (newItem.rememberDaily) {
                  customerItem.rememberDaily = newItem.rememberDaily;
                }

                if (newItem.infoTexts) {
                  customerItem.infoTexts = newItem.infoTexts;
                }

                if (newItem.DynTemplate) {
                  customerItem.DynTemplate = newItem.DynTemplate;
                }

                if (newItem.Vorlage) {
                  customerItem.Vorlage = newItem.Vorlage;
                }

                  if (newItem.type) {
                  customerItem.type = newItem.type;
                }
              }
            }
          }
        }

        await this.updateCustomerCategories(customer.uid, customerCategories);
      }
    },
    async updateCustomerCategories(customerDocId, categories) {
      let _collection = collection(firestore, "customerprofile/");
      let _doc = doc(_collection, customerDocId);

      let data = {
        Kategorien: categories,
      };
      await updateDoc(_doc, data);
      console.log("Update OK", customerDocId);
    },
    async _updateDoc(data) {
      let _collection = collection(firestore, "kategorien/");
      let _doc = doc(_collection, data.docId);

      await updateDoc(_doc, data);
    },
    async save() {
      for (let cat of this.categories) {
        await this._updateDoc(cat);
        console.log("OK", cat.title, cat);
      }
    },
    addit(i) {
      console.log(i);
      if (!i.infoTexts) {
        i.infoTexts = [];
      }
      let obj = { order: 0, text: "" };
      i.infoTexts.push(obj);
      this.componentKey++;
    },
    removeit(i, index) {
      i.infoTexts.splice(index, 1);
      this.componentKey++;
    },
    async loadKats() {
      let result = [];
      let categories = await getDocs(
        query(collection(firestore, "kategorien"), orderBy("order", "asc"))
      );

      for (let doc of categories.docs) {
        let data = { ...doc.data() };
        data.docId = doc.id;
        result.push(data);
      }
      result.sort((c) => c.order);
      this.categories = result;
    },
  },
  async mounted() {
    await this.loadKats();
  },
};
</script>

<style>
</style>