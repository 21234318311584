var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"action-item",on:{"click":function($event){return _vm.itemClicked()}}},[_c('div',{class:[
      'item-title',
      _vm.zeigeFortschritt === true
        ? _vm.itemsCompleted.quantity == _vm.itemsCompleted.count
          ? 'complete'
          : 'progress'
        : '' ]},[_c('h4',[_vm._v(_vm._s(_vm.kategorie.title))]),_c('div',{staticClass:"button-group"},[_c('div',{class:[
          'tag',
          _vm.zeigeFortschritt === true
            ? _vm.itemsCompleted.quantity == _vm.itemsCompleted.count
              ? 'complete'
              : 'progress'
            : '' ]},[_vm._v(" "+_vm._s(_vm.zeigeFortschritt === true ? _vm.itemsCompleted.quantity : _vm.itemsToComplete.quantity)+"/"+_vm._s(_vm.zeigeFortschritt === true ? _vm.itemsCompleted.count : _vm.itemsToComplete.count)+" ")]),(false && _vm.zeigeFortschritt)?_c('button',{staticClass:"button-menu icon-download",attrs:{"disabled":_vm.itemsCompleted.quantity == 0},on:{"click":function($event){$event.stopPropagation();return _vm.downloadDocuments()}}},[_vm._v(" Dokument downloaden ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }