import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


import { auth } from "@/plugins/firebase";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";

import './assets/styles.css';
import './assets/mystyles.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';



Vue.config.productionTip = false


router.beforeEach(async (to, from, next) => {

  next();
});

const DEFAULT_TITLE = 'Kundenportal - Florian Thoms';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + " - " + DEFAULT_TITLE || DEFAULT_TITLE;
  });
});

auth.onAuthStateChanged(async (user) => {
  //console.log("onAuthStateChanged");
  //console.log(user);
  store.state.user = user;
  if (user) {
    let uid = user.uid;

    let fakeUser = false;
    if (fakeUser) {
      uid = "aafuYI8u3mVxbgbUhpdY2qNoN6x1";
      store.state.user.uid = uid;
    }


    let customerprofile = await firestoreFunctionsMixin.methods.loadcustomerprofile(uid);
    store.state.customerprofile = customerprofile;

    let employeeprofile = await firestoreFunctionsMixin.methods.loademployeeprofile(uid);
    store.state.employeeprofile = employeeprofile;

    store.state.auth.isSignedIn = true;

    await store.dispatch("loadInitialData");
  } else {
    store.state.auth.isSignedIn = false;
  }
  store.state.auth.authDone = true;
});

Vue.component("loading", Loading);



window.vueApp = new Vue({
  router,
  store,
  vuetify,

  render: function (h) {
    return h(App)
  }
}).$mount('#app')