<template>
  <div>
    <customDialog ref="cstmdlg" />
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>
    <v-file-input
      v-show="false"
      :ref="'fileUpload'"
      accept="image/png, image/jpeg"
      label="File input"
      @change="(file) => setAvatar(file)"
    ></v-file-input>

    <header class="dashboard-header">
      <div class="header-headline">
        <h1>Mein Profil</h1>
        <div class="button-group">
          <a @click="abort()" class="button-tertiary icon-close">Abbrechen</a>
          <button class="button-primary" @click="saveProfile()">
            Speichern
          </button>
        </div>
      </div>
    </header>

    <div class="dashboard-content">
      <div class="grid-32 4-4-4">
        <div class="card">
          <div class="card-header">
            <h3>Meine Daten</h3>
          </div>
          <form class="card-form">
            <div class="form-field">
              <label>Anrede</label>
              <select name="anrede" v-model="$store.state.employeeprofile.Anrede">
                <option value="Frau">Frau</option>
                <option value="Herr">Herr</option>
              </select>
            </div>
            <div class="form-field">
              <label id="vorname">Vorname</label>
              <input
                type="text"
                name="vorname"
                v-model="$store.state.employeeprofile.Vorname"
              />
            </div>
            <div class="form-field">
              <label for="nachname">Nachname</label>
              <input
                id="nachname"
                type="text"
                name="nachname"
                v-model="$store.state.employeeprofile.Nachname"
              />
            </div>
            <div class="form-field">
              <label for="tel">Telefon</label>
              <input
                id="tel"
                type="tel"
                name="telefon"
                v-model="$store.state.employeeprofile.Telefon"
              />
            </div>
            <div class="form-field">
              <label for="email">E-Mail</label>
              <input
                id="email"
                type="email"
                name="email"
                v-model="$store.state.employeeprofile.Email"
              />
            </div>
            <div class="form-field upload-image">
              <img
                style="
                  cursor: pointer;
                  min-width: 100%;
                  min-height: 400px;
                  border: 1px solid black;
                "
                @click="selectImage"
                :src="getAvatarOrDummy($store.state.employeeprofile)"
              />
            </div>
            <div class="form-field">
              <v-switch
                name="useonedrivesync"
                v-model="$store.state.employeeprofile.useOneDriveSync"
                :label="`OneDrive Synchronisation verwenden`"
              ></v-switch>
            </div>
            <div
              class="form-field"
              v-if="$store.state.employeeprofile.useOneDriveSync === true"
            >
              <label for="onedriveclientid">OneDrive ClientID</label>
              <input
                id="onedriveclientid"
                type="text"
                name="onedriveclientid"
                v-model="$store.state.employeeprofile.OneDrive_clientid"
              />
            </div>
            <div
              class="form-field"
              v-if="$store.state.employeeprofile.useOneDriveSync === true"
            >
              <label for="onedriveclientsecret">OneDrive ClientSecret</label>
              <input
                id="onedriveclientsecret"
                type="password"
                name="onedriveclientsecret"
                v-model="$store.state.employeeprofile.OneDrive_clientsecret"
              />
            </div>
            <div
              class="form-field"
              v-if="$store.state.employeeprofile.useOneDriveSync === true"
            >
              <button
                class="button-primary"
                @click.prevent="getOneDriveAuthorization()"
              >
                OneDrive berechtigen
              </button>
            </div>
          </form>
        </div>

        <div
          class="card checklist"
          v-if="
            $store.state.employeeprofile.Rollen.includes('Vertriebspartner')
          "
        >
          <div class="card-header">
            <h3>Vertretung auswählen</h3>
          </div>
          <div class="action-list" :key="componentKey">
            <employee
              v-for="employee in $store.state.allSalesPartners.filter(
                (e) => e.uid !== $store.state.user.uid
              )"
              :key="employee.uid"
              :isSelected="
                $store.state.employeeprofile.Vertreter.includes(employee.uid)
              "
              @selected="(e) => selectDeputy(e)"
              :employee="employee"
              :radio="true"
            />
          </div>
        </div>

        <div
          class="card checklist"
          v-if="
            $store.state.employeeprofile.Rollen.includes('Vertriebspartner')
          "
        >
          <div class="card-header">
            <h3>Assistenz auswählen</h3>
          </div>
          <div class="action-list" :key="componentKey">
            <employee
              v-for="employee in $store.state.allAssistants.filter(
                (e) => e.uid !== $store.state.user.uid
              )"
              :key="employee.uid"
              :isSelected="
                $store.state.employeeprofile.myAssistants.includes(employee.uid)
              "
              @selected="(e) => selectAssistant(e)"
              :employee="employee"
              :radio="true"
            />
          </div>
        </div>

        <div
          class="card checklist"
          v-if="$store.state.employeeprofile.Rollen.includes('Assistenz')"
        >
          <div class="card-header">
            <h3>Ich bin Assistenz von</h3>
          </div>
          <div
            class="action-list"
            :key="componentKey"
            v-if="
              $store.state.allSalesPartners.filter((e) =>
                e.myAssistants.includes($store.state.employeeprofile.uid)
              ).length > 0
            "
          >
            <employee
              v-for="employee in $store.state.allSalesPartners.filter((e) =>
                e.myAssistants.includes($store.state.employeeprofile.uid)
              )"
              :key="employee.uid"
              :employee="employee"
              :removeAssistant="true"
              @removeAssistant="removeAssistant"
            />
          </div>
          <div v-else>Du bist nicht zugewiesen</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import imageFunctionsMixin from "@/mixins/imageFunctions.js";

import { functions } from "@/plugins/firebase";
import { httpsCallable } from "@firebase/functions";

import unterlagenKategorie from "@/components/unterlagenKategorie";
import employee from "@/components/employee";

import { firestore } from "@/plugins/firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
} from "@firebase/firestore";
import { ref, uploadBytes, uploadString } from "@firebase/storage";
import { storage } from "../../../plugins/firebase";
import customDialog from "@/components/customDialog";

export default {
  name: "Profil",
  mixins: [routingFunctionsMixin, firestoreFunctionsMixin, imageFunctionsMixin],

  components: { unterlagenKategorie, employee, customDialog },
  data() {
    return {
      componentKey: 0,
      initialEmployeeProfile: null,
      isLoading: false,
    };
  },
  methods: {
    async removeAssistant(employee) {
      this.$refs.cstmdlg.showDialog(
        "Assistenz entfernen",
        `Bist du sicher dass du nicht mehr die Assistenz von ${employee.Vorname} ${employee.Nachname} sein möchtest? ${employee.Vorname} wird per Email über diese Änderung informiert.`,
        {
          text: "Ja",
          color: "primary",
          fnc: async () => {
            this.isLoading = true;
            try {
              const removeAssistant = httpsCallable(
                functions,
                "removeAssistant"
              );
              const data = await removeAssistant({
                accessToken: this.$store.state.user.accessToken,
                employee: employee,
              });
            } catch (error) {}
            this.isLoading = false;
          },
        },
        {
          text: "Abbruch",
          color: "primary",
          fnc: () => {},
        },
        true
      );
    },

    async OneDriveTest() {
      const oneDriveTest = httpsCallable(functions, "oneDriveTest");
      const data = await oneDriveTest({
        accessToken: this.$store.state.user.accessToken,
      });
    },
    async getOneDriveAuthorization() {
      await this.saveProfile();
      let scope = "onedrive.readwrite%20offline_access";
      let response_type = "code";
      let redirectUri =
        "https://kundenportal.florian-thoms.de/callback_onedrive";

      if (process.env.NODE_ENV === "development") {
        redirectUri = "http://localhost:8080/callback_onedrive";
      }

      let url = `https://login.live.com/oauth20_authorize.srf?client_id=${this.$store.state.employeeprofile.OneDrive_clientid}&scope=${scope}&response_type=${response_type}&redirect_uri=${redirectUri}`;
      window.location = url;
    },

    setAvatar(file) {
      var self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      this.$store.state.employeeprofile.AvatarFile = file;
      reader.onload = () => {
        let base64 = reader.result;
        this.$store.state.employeeprofile.Avatar = base64;
        this.$store.state.employeeprofile.newAvatar = true;
        this.componentKey++;
      };
    },
    selectImage() {
      let fileupload = this.$refs["fileUpload"];
      fileupload.$refs.input.click();
    },
    abort() {
      this.$store.state.employeeprofile = this.initialEmployeeProfile;
      this.gotoByName("DBBackend");
    },
    async saveProfile(silent = false) {
      this.isLoading = true;
      let _collection = collection(firestore, "employeeprofile/");
      let _doc = doc(_collection, this.$store.state.employeeprofile.uid);

      try {
        let storagePath = `FlorianThoms/ProfilePictures/${this.$store.state.user.uid}.jpg`;
        const storageRef = ref(storage, storagePath);

        if (
          this.$store.state.employeeprofile.newAvatar &&
          this.$store.state.employeeprofile.newAvatar === true
        ) {
          await uploadBytes(
            storageRef,
            this.$store.state.employeeprofile.AvatarFile
          );
          delete this.$store.state.employeeprofile.Avatar;
        }

        this.$store.state.employeeprofile.lastUpdateBy =
          this.$store.state.user.uid;
        this.$store.state.employeeprofile.lastUpdateAt = new Date();

        delete this.$store.state.employeeprofile.AvatarFile;
        delete this.$store.state.employeeprofile.newAvatar;
        delete this.$store.state.employeeprofile.Avatar;
        console.log(this.$store.state.employeeprofile.Avatar);

        await setDoc(_doc, this.$store.state.employeeprofile);

        this.initialEmployeeProfile = JSON.parse(
          JSON.stringify(this.$store.state.employeeprofile)
        );

        if (!silent) {
          this.$store.dispatch("showSnackbar", {
            text: "Erfolgreich gespeichert",
            color: "green",
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("showSnackbar", {
          text: "Speichern fehlegeschlagen",
          color: "red",
        });
      }

      this.isLoading = false;
    },
    getEmployeesButMe() {
      let result = [];
      for (let i = 0; i < this.$store.state.allEmployees.length; i++) {
        const employee = this.$store.state.allEmployees[i];
        if (employee.uid !== this.$store.state.employeeprofile.uid) {
          result.push(employee);
        }
      }
      return result;
    },

    selectDeputy(employee) {
      let idx = this.$store.state.employeeprofile.Vertreter.indexOf(
        employee.uid
      );

      if (idx >= 0) {
        this.$store.state.employeeprofile.Vertreter.splice(idx, 1);
      } else {
        this.$store.state.employeeprofile.Vertreter.push(employee.uid);
      }
    },
    selectAssistant(employee) {
      let idx = this.$store.state.employeeprofile.myAssistants.indexOf(
        employee.uid
      );
      if (idx >= 0) {
        this.$store.state.employeeprofile.myAssistants.splice(idx, 1);
      } else {
        this.$store.state.employeeprofile.myAssistants.push(employee.uid);
      }
    },
  },
  computed: {},
  async mounted() {
    if (!this.$store.state.user) {
      this.$router.replace({ name: "Login" });
      return;
    }
    this.isLoading = true;
    this.initialEmployeeProfile = JSON.parse(
      JSON.stringify(this.$store.state.employeeprofile)
    );

    this.isLoading = false;
  },
};
</script>