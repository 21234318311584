import Vue from 'vue'
import Vuex from 'vuex'



import {
  auth,
  firestore,
  storage
} from '@/plugins/firebase'

import {
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  doc,
  setDoc,
  onSnapshot,
  where,
  getDoc
} from "@firebase/firestore";

import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginErrorMsg: "",
    auth: {
      authDone: false,
      isSignedIn: false,
    },
    user: null,
    customerprofile: null,
    employeeprofile: null,

    allCategories: null,

    allEmployees: null,
    allSalesPartners: null,
    allAssistants: null,

    customerData: {
      vertriebspartner: null
    },
    snackbar: {
      timeout: 5000,
      color: "green",
      text: "Standard",
      visible: false,
      handler: null
    },
    myCustomers: [],
    customersChangedSnapshotListener: null,
    avatars: [],

  },
  mutations: {},
  actions: {
    async saveEmployeeProfile() {
      try {
        let _collection = collection(firestore, "employeeprofile/");
        let _doc = doc(_collection, this.state.employeeprofile.uid);
        await setDoc(_doc, this.state.employeeprofile);
        return true;
      }
      catch (err) {
        console.log(err);
      }
      return false;
    },

    showSnackbar({ commit }, payload) {
      this.state.snackbar.text = payload.text;
      this.state.snackbar.color = payload.color;

      clearTimeout(this.state.snackbar.handler);

      this.state.snackbar.visible = true;
      this.state.snackbar.handler = setTimeout(() => {
        this.state.snackbar.visible = false;
      }, this.state.snackbar.timeout);
    },

    async loadInitialData() {
      //console.log("loadInitialData", this.state);
      if (this.state.employeeprofile) {
        await this.dispatch("initEmployeesChangedListener");
        this.dispatch("loadAllAvatars");

        await this.dispatch("initAllCategoriesChangedListener");

        //await this.dispatch("initAllCustomersChangedListener");


      } else if (this.state.customerprofile) {
        await this.dispatch("initCustomerChangedListener");
      }
    },

    async loadAllAvatars({ commit }) {
      const { setAllAvatars } = require("../plugins/helperfunctions.js");
      setAllAvatars(this);
    },


    async initEmployeesChangedListener({ commit }) {
      const q = query(collection(firestore, "employeeprofile"));

      this.state.allEmployees = [];


      return new Promise((resolve, reject) => {
        onSnapshot(q, async (querySnapshot) => {

          //let allAvatars = await getAllAvatars();
          let allAvatars = [];

          console.log("Data changed", allAvatars);


          this.state.allEmployees = [];
          this.state.allSalesPartners = [];
          this.state.allAssistants = [];

          for (let i in querySnapshot.docs) {
            const doc = querySnapshot.docs[i];


            let data = doc.data();
            for (let a of allAvatars) {
              if (a.filename === data.uid + ".jpg") {
                data.Avatar = a.b64;
              }
            }

            if (data.uid === this.state.user.uid) {
              this.state.employeeprofile = data;
            }

            if (data.invisible) {
              continue;
            }

            this.state.allEmployees.push(data);
            if (data.Rollen.includes("Vertriebspartner")) {
              this.state.allSalesPartners.push(data);
            }

            if (data.Rollen.includes("Assistenz")) {
              this.state.allAssistants.push(data);
            }
          };

          if (this.state.customersChangedSnapshotListener) {
            await this.state.customersChangedSnapshotListener();
          }
          await this.dispatch("initAllCustomersChangedListener");


          resolve();
        });
      });

    },

    async initAllCustomersChangedListener({ commit }) {
      if (this.state.employeeprofile.siehtKundenVon && this.state.employeeprofile.siehtKundenVon.length > 0) {
        this.state.myCustomers = [];


        if (this.state.employeeprofile.Rollen.includes("SiehtAlleKunden")) {

          let snapshot = await getDocs(query(collection(firestore, "customerprofile")));
          for (let doc of snapshot.docs) {
            let data = {
              ...doc.data()
            };
            this.state.myCustomers.push(data);
          }
          return;
        }


        if (this.state.employeeprofile.siehtKundenVon.length <= 10) {
          console.log("hier");
          let snapshot = await getDocs(query(collection(firestore, "customerprofile"), where("Vertriebspartner", "in", this.state.employeeprofile.siehtKundenVon)));
          for (let doc of snapshot.docs) {
            let data = {
              ...doc.data()
            };
            this.state.myCustomers.push(data);
          }
          return;

        } else {
          for (let vp of this.state.employeeprofile.siehtKundenVon) {
            const q = query(collection(firestore, "customerprofile"), where("Vertriebspartner", "==", vp));
            let snapshot = await getDocs(q);

            for (let doc of snapshot.docs) {
              let data = {
                ...doc.data()
              };
              this.state.myCustomers.push(data);
            }
          }
        }


      }


    },

    initCustomerChangedListener({ commit }) {
      let _collection = collection(firestore, "customerprofile");
      let _doc = doc(_collection, this.state.user.uid);
      const { downloadAvatar } = require("../plugins/helperfunctions.js");

      return new Promise((resolve, reject) => {

        onSnapshot(_doc, async (snapshot) => {
          let data = snapshot.data();
          this.state.customerprofile = data;

          let _collection = collection(firestore, "employeeprofile/");
          let _doc = doc(_collection, data.Vertriebspartner);
          let _data = await getDoc(_doc);
          this.state.customerData.vertriebspartner = _data.data();

          this.state.customerData.vertriebspartner.Avatar = await downloadAvatar(data.Vertriebspartner);


          resolve();
        });

      });
    },

    initAllCategoriesChangedListener({ commit }) {
      const q = query(collection(firestore, "kategorien"), orderBy("order", "asc"))

      return new Promise((resolve, reject) => {
        onSnapshot(q, (querySnapshot) => {
          this.state.allCategories = [];

          querySnapshot.forEach((doc) => {
            let data = doc.data();
            this.state.allCategories.push(data);
          });

          resolve();
        });
      });

    },





    userSignOut() {

      console.log("logout");
      auth.signOut();
      this.state.user = null;
      this.state.customerprofile = null;
      this.state.employeeprofile = null;
      this.state.auth.isSignedIn = false;
      router.push({
        name: "Login"
      });
    },

  },
  getters: {

  },
  modules: {}
})