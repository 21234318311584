<template>
  <div>
    <header :class="['header', classes]">
      <div class="header-logo"  >
        <div class="logo-image" style="cursor:pointer" @click="gotoByName('Login')">
          <img src="@/assets/images/logo-ft.svg"  />
        </div>
        <div class="logo-title" style="cursor:pointer" @click="gotoByName('Login')">Kundenportal</div>
      </div>
      <div class="button-group">

        <button class="button-tertiary icon-logout" @click="logout()" v-if="this.$store.state.auth.isSignedIn">
          Abmelden
        </button>

        <a @click="gotoByName('Profil')" class="button-profile" v-if="this.$store.state.auth.isSignedIn && this.$store.state.employeeprofile">
          <img :src="getAvatarOrDummy(this.$store.state.employeeprofile)"/>
        </a>

        <div v-if="this.$route.name == 'LoginBackend'"> 
          <button class="button-tertiary" @click="gotoByName('Login')">
            Als Kunde anmelden
          </button>
        </div>

      </div>
    </header>
  </div>
</template>

<script>
import imageFunctionsMixin from "@/mixins/imageFunctions.js";

import routingFunctionsMixin from "@/mixins/routingFunctions.js";

export default {
  name: "Header",
  mixins: [routingFunctionsMixin, imageFunctionsMixin],
  data() {
    return {};
  },
  methods: {
   
    logout() {
      this.$store.dispatch("userSignOut");
    },
  },
  computed: {
    classes() {
      if(navigator.userAgent == 'kundenportal_florianthoms_app'){
        return "appHeader";
      }
      return ""; 
    }
  },
  mounted() {
    console.log(navigator.userAgent);
    //console.log(this.$store.state.auth.isSignedIn);
  },
};
</script>

<style scoped>
  .appHeader{
    padding-top: 14px;
  }
</style>