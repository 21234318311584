import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"dashboard-header"},[_c('div',{staticClass:"header-headline"},[_c('h1',{attrs:{"id":"h1welcome"}},[_vm._v(" 👋 Hallo "+_vm._s(this.$store.state.employeeprofile.Vorname)+" ")]),_c('div',{staticClass:"button-group"},[(
            _vm.$store.state.employeeprofile.Rollen.includes(
              'MitarbeiterVerwalten'
            )
          )?_c('a',{staticClass:"button-secondary",attrs:{"id":"btn-mitarbeiterverwaltung"},on:{"click":function($event){return _vm.gotoByName('EmployeesOverview')}}},[_vm._v("Mitarbeiterverwaltung ")]):_vm._e(),(_vm.$store.state.employeeprofile.Rollen.includes('KundenAnlegen'))?_c('a',{staticClass:"button-primary",attrs:{"id":"btn-kundeanlegen"},on:{"click":function($event){return _vm.gotoByName('NeuerKunde')}}},[_vm._v("Neuen Kunden anlegen")]):_vm._e()])])]),(_vm.loading)?_c('div',[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"grid-32"},[_c('div',{staticClass:"block-action"},[_vm._m(0),_c('div',[_c(VSwitch,{attrs:{"label":"Zeige nur Aktive Kunden"},model:{value:(_vm.showOnlyActive),callback:function ($$v) {_vm.showOnlyActive=$$v},expression:"showOnlyActive"}})],1),_c('div',{key:_vm.customersKey,staticClass:"action-list"},_vm._l((_vm.filteredCustomers),function(k){return _c('kundenUebersicht',{key:k.id,attrs:{"kunde":k}})}),1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-header"},[_c('h3',[_vm._v("Kundenübersicht")])])}]

export { render, staticRenderFns }