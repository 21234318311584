<template>
  <div>
    <v-dialog persistent v-model="showDeleteDialog" width="700">
      <v-card>
        <v-card-title
          class="text-h5 lighten-2"
          style="background-color: #f4f9fe"
        >
          Mitarbeiter löschen
        </v-card-title>
        <br />
        <v-card-text>
          <p style="font-size: 20px">
            Soll der Mitarbeiter {{ employeeToDelete.Vorname }}
            {{ employeeToDelete.Nachname }} wirklich gelöscht werden?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteEmployee(employeeToDelete)">
            Ja
          </v-btn>
          <v-btn color="danger" text @click="showDeleteDialog = false">
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <loading
        :active="isLoading"
        :color="'#78AEF5'"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>
    <v-file-input
      v-show="false"
      :ref="'fileUpload'"
      accept="image/png, image/jpeg"
      label="File input"
      @change="(file) => setAvatar(file)"
    ></v-file-input>

    <div>
      <header class="dashboard-header">
        <div class="header-headline">
          <h1>Mitarbeiterverwaltung</h1>
          <div class="button-group">
            <a
              class="button-tertiary icon-close"
              @click="gotoByName('DBBackend')"
              >Abbrechen</a
            >
          </div>
        </div>
      </header>

      <div class="dashboard-content">
        <div class="grid-32 4-8">
          <div class="card">
            <div class="card-header">
              <h3>Neuer Mitarbeiter</h3>
            </div>
            <div class="card-form">
              <div class="form-field">
                <label>Anrede</label>
                <select name="anrede" v-model="newEmployee.Anrede">
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                </select>
              </div>
              <div class="form-field">
                <label id="vorname">Vorname</label>
                <input
                  type="text"
                  name="vorname"
                  v-model="newEmployee.Vorname"
                />
              </div>
              <div class="form-field">
                <label for="nachname">Nachname</label>
                <input
                  id="nachname"
                  type="text"
                  name="nachname"
                  v-model="newEmployee.Nachname"
                />
              </div>
              <div class="form-field">
                <label for="tel">Telefon</label>
                <input
                  id="tel"
                  type="tel"
                  name="telefon"
                  v-model="newEmployee.Telefon"
                />
              </div>
              <div class="form-field">
                <label for="email">E-Mail</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  v-model="newEmployee.Email"
                />
              </div>
              <div class="form-field">
                <v-switch
                  name="vertriebspartner"
                  v-bind:input-value="
                    newEmployee.Rollen.includes('Vertriebspartner')
                  "
                  @change="(value) => setRole('Vertriebspartner', value)"
                  :label="`Vertriebspartner`"
                ></v-switch>
              </div>
              <div class="form-field">
                <v-switch
                  name="Assistenz"
                  v-bind:input-value="newEmployee.Rollen.includes('Assistenz')"
                  @change="(value) => setRole('Assistenz', value)"
                  :label="`Assistenz`"
                ></v-switch>
              </div>
              <div class="form-field upload-image">
                <img
                  class=""
                  style="
                    cursor: pointer;
                    min-width: 100%;
                    min-height: 400px;
                    border: 1px solid black;
                  "
                  @click="selectImage"
                  :src="getAvatarOrDummy(newEmployee)"
                />
              </div>
              <div class="form-footer">
                <button
                  id="btn-add"
                  class="button-primary"
                  @click="registerNewEmployee"
                  :disabled="
                    !$store.state.employeeprofile.Rollen.includes(
                      'MitarbeiterAnlegen'
                    )
                  "
                >
                  Hinzufügen
                </button>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h3>Mitarbeiter</h3>
            </div>
            <div class="action-list overflow-scroll-y" :key="employeeKey">
              <employee
                v-for="employee in $store.state.allEmployees"
                :key="employee.uid"
                @clickOnDelete="EmployeeClickOnDelete"
                :employee="employee"
                :setRoles="allowedToSetRoles"
                :deleteEmployees="allowedToDeleteEmployees"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import imageFunctionsMixin from "@/mixins/imageFunctions.js";
import customFunctionsMixin from "@/mixins/customFunctions.js";

import employee from "@/components/employee.vue";
import kundenUebersicht from "@/components/kundenUebersicht.vue";

import { functions } from "@/plugins/firebase";
import { httpsCallable } from "@firebase/functions";
import { ref, uploadBytes } from "@firebase/storage";
import { storage } from "../../../plugins/firebase";

export default {
  name: "EmployeesOverview",
  mixins: [
    routingFunctionsMixin,
    firestoreFunctionsMixin,
    customFunctionsMixin,
    imageFunctionsMixin,
  ],
  components: { kundenUebersicht, employee },
  data() {
    return {
      newEmployee: {
        Vorname: "",
        Nachname: "",
        Telefon: "",
        Email: "",
        Avatar: "",
        Rollen: ["Vertriebspartner"],
        uid: "newEmployee",
        Anrede: "Frau",
      },
      employeeKey: 0,
      isLoading: false,
      employeeToDelete: {
        Vorname: "",
        Nachname: "",
      },
      showDeleteDialog: false,
    };
  },
  methods: {
    setRole(role, value) {
      this.newEmployee.Rollen = [];
      if (value === true && !this.newEmployee.Rollen.includes(role)) {
        this.newEmployee.Rollen.push(role);
      }

      if (value === false && this.newEmployee.Rollen.includes(role)) {
        this.newEmployee.Rollen.splice(
          this.newEmployee.Rollen.indexOf(role),
          1
        );
      }
    },
    EmployeeClickOnDelete(employee) {
      this.employeeToDelete = employee;
      this.showDeleteDialog = true;
    },
    async deleteEmployee(employee) {
      try {
        const deleteEmployee = httpsCallable(functions, "deleteEmployee");
        const response = await deleteEmployee({
          uidToDelete: employee.uid,
          accessToken: this.$store.state.user.accessToken,
        });
        if (response.data.code === 0) {
          this.$store.dispatch("showSnackbar", {
            text: "Der Mitarbeiter wurde erfolgreich gelöscht. Eventuell vorhandene Kunden wurden Florian Thoms zugewiesen.",
            color: "green",
          });
        }else{
           this.$store.dispatch("showSnackbar", {
          text: response.data.msg,
          color: "red",
        });
        }
      } catch (err) {
        this.$store.dispatch("showSnackbar", {
          text: "Der Mitarbeiter konnte nicht gelöscht werden.",
          color: "red",
        });
      }

      this.showDeleteDialog = false;
    },
    setAvatar(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      this.$refs["fileUpload"].$refs.input.value = null;
      reader.onload = () => {
        let base64 = reader.result;

        this.$store.state.avatars.push({
          filename: "newEmployee.jpg",
          file: file,
          image: base64,
        });

        this.newEmployee.newAvatar = true;

        this.employeeKey++;
      };
    },
    selectImage() {
      let fileupload = this.$refs["fileUpload"];
      fileupload.$refs.input.click();
    },

    async registerNewEmployee() {
      if (this.validateForm()) {
        this.isLoading = true;

        try {
          delete this.newEmployee.Avatar;
          const createEmployee = httpsCallable(functions, "createEmployee");
          const response = await createEmployee({
            newEmployee: this.newEmployee,
            accessToken: this.$store.state.user.accessToken,
          });

          if (response.data.code === 0) {
            let storagePath = `FlorianThoms/ProfilePictures/${response.data.employeeuid}.jpg`;
            const storageRef = ref(storage, storagePath);

            if (this.newEmployee.newAvatar) {
              let avatar = this.$store.state.avatars.find(
                (a) => a.filename === "newEmployee.jpg"
              );
              await uploadBytes(storageRef, avatar.file);

              let index = this.$store.state.avatars.indexOf(
                (a) => a.filename === "newEmployee.jpg"
              );
              this.$store.state.avatars.splice(index, 1);
            }

            this.$store.dispatch;

            this.$router.push({ name: "DBBackend" });
            this.$store.dispatch("showSnackbar", {
              text: response.data.msg,
              color: "green",
            });
          } else {
            this.$store.dispatch("showSnackbar", {
              text: response.data.msg,
              color: "red",
            });
          }
        } catch (err) {
          this.$store.dispatch("showSnackbar", {
            text: "Ein Fehler ist aufgetreten",
            color: "red",
          });
          console.log(err);
        }
        this.isLoading = false;
      }
    },
    validateForm() {
      let requiredFields = ["vorname", "nachname", "email", "telefon"];
      let validations = {
        vorname: {
          func: () => {
            let minLength = 1;
            let prop = this.getValTrimmedOrDefault(
              this.newEmployee.Vorname,
              ""
            );
            return prop.length >= minLength;
          },
          errorMsg: "Vorname muss mindestens 1 Zeichen lang sein",
        },
        nachname: {
          func: () => {
            let minLength = 1;
            let prop = this.getValTrimmedOrDefault(
              this.newEmployee.Nachname,
              ""
            );
            return prop.length >= minLength;
          },
          errorMsg: "Nachname muss mindestens 1 Zeichen lang sein",
        },
        email: {
          func: () => {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              this.getValTrimmedOrDefault(this.newEmployee.Email, "")
            );
          },
          errorMsg: "Keine valide Email-Adresse angegeben",
        },
        telefon: {
          func: () => {
            let minLength = 6;
            let prop = this.getValTrimmedOrDefault(
              this.newEmployee.Telefon,
              ""
            );
            return prop.length >= minLength;
          },
          errorMsg: `Die Telefonnummer muss mindestens 6 Zeichen lang sein`,
        },
      };

      for (let i = 0; i < requiredFields.length; i++) {
        const fieldName = requiredFields[i];

        if (validations[fieldName] !== undefined) {
          if (!validations[fieldName].func()) {
            this.$store.dispatch("showSnackbar", {
              text: validations[fieldName].errorMsg,
              color: "red",
            });
            console.log(validations[fieldName].errorMsg);

            return false;
          }
        }
      }
      return true;
    },
  },
  computed: {
    allowedToSetRoles() {
      return this.$store.state.employeeprofile.Rollen.includes(
        "RollenVergeben"
      );
    },
    allowedToDeleteEmployees() {
      return this.$store.state.employeeprofile.Rollen.includes(
        "MitarbeiterLoeschen"
      );
    },
  },
  async mounted() {
    if (!this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "Login" });
      return;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>