<template>
  <div v-if="this.$store.state.customerprofile">
    <v-dialog persistent v-model="showGratulations" width="700">
      <v-card>
        <v-card-title
          class="text-h5 lighten-2"
          style="background-color: #f4f9fe"
        >
          Finanzierungszusage
        </v-card-title>
        <br />
        <v-card-text>
          <p style="font-size: 20px">
            Herzlichen Glückwunsch zu deiner Finanzierungszusage 😊🎉🍀
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="setGratulationAsRead">
            Gelesen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <header class="dashboard-header">
      <div class="header-headline">
        <h1 id="h1welcome" v-if="activeTab == 1">
          👋 Hallo
          {{
            this.$store.state.customerprofile
              ? this.$store.state.customerprofile.Vorname
              : ""
          }}
        </h1>
        <div v-if="activeTab >= 2" class="headline-tag">
          Erforderliche Unterlagen
        </div>
        <h1 v-if="activeTab >= 2">{{ getKatByID(activeTab).title }}</h1>

        <div class="button-group">
          <a
            class="button-tertiary icon-back"
            v-if="activeTab > 1"
            @click="goBack"
            >Zurück</a
          >
        </div>
      </div>
    </header>

    <div class="dashboard-content">
      <div class="grid-32 8-4 lg-7-5">
        <div class="grid-32" v-if="activeTab == 1">
          <div class="block-welcome">
            <h2>Willkommen im Kundenportal für deine Baufinanzierung.</h2>
            <br />
            Nachfolgend findest du eine Tabellte mit den erforderlichen
            Unterlagen. Du kannst alles ganz einfach hochladen und
            fotografieren. Solltest du Fragen haben, dann kannst du mir einfach
            eine E-Mail schreiben oder mich anrufen.
          </div>
          <div class="block-action">
            <div class="action-header">
              <h3>Erforderliche Unterlagen</h3>
              <div class="header-progress">
                Vollständigkeit:
                <span
                  :class="[
                    'tag',
                    itemsToComplete.count == itemsToComplete.erledigt
                      ? 'complete'
                      : 'progress',
                  ]"
                  >{{ itemsToComplete.erledigt }}/{{
                    itemsToComplete.count
                  }}</span
                >
              </div>
            </div>
            <div class="action-list">
              <unterlagenKategorieCustomer
                :kategorie="kat"
                :id="kat.id"
                @itemClicked="activeTab = kat.id"
                v-for="kat in kategoriesToShow()"
                v-bind:key="kat.id"
                :zeigeFortschritt="true"
                :disable="
                  kat.id == 5 &&
                  !$store.state.customerprofile.AuszahlungAktiviert
                "
              />
            </div>
          </div>
        </div>

        <template v-for="kat in kategoriesToShow()">
          <div :key="kat.id" v-if="activeTab == kat.id">
            <div class="grid-32" :key="componentKey">
              <unterlagenKategorieUeberschriftCustomer
                :categoryName="kat.title"
                :kategorieUeberschrift="ueberschrift"
                v-for="ueberschrift in headlinesToShow(kat)"
                :key="ueberschrift.id"
                @collapseAllItems="collapseAllItems"
              />
            </div>
          </div>
        </template>

        <div class="grid-32">
          <div class="card contact">
            <div class="contact-avatar">
              <img
                :src="
                  getAvatarOrDummy($store.state.customerData.vertriebspartner)
                "
                alt="Florian Thoms"
              />
            </div>
            Dein Ansprechpartner
            <h3>
              {{ $store.state.customerData.vertriebspartner.Vorname }}
              {{ $store.state.customerData.vertriebspartner.Nachname }}
            </h3>
            <div class="card-button-group">
              <a
                class="button-primary icon-phone"
                :href="
                  'tel:' + $store.state.customerData.vertriebspartner.Telefon
                "
                >{{ $store.state.customerData.vertriebspartner.Telefon }}</a
              >
              <a
                class="button-primary icon-email"
                :href="
                  'mailto:' + $store.state.customerData.vertriebspartner.Email
                "
                >E-Mail</a
              >
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3>Meine persönlichen Daten</h3>
            </div>
            {{ $store.state.customerprofile.Vorname }}
            {{ $store.state.customerprofile.Nachname }}<br />
            {{ $store.state.customerprofile.Strasse }}
            {{ $store.state.customerprofile.Hausnummer }}<br />
            {{ $store.state.customerprofile.Plz }}
            {{ $store.state.customerprofile.Ort }}<br />
            <br />
            <span class="tag-contact">Telefon:</span
            >{{ $store.state.customerprofile.Telefon }}<br />
            <span class="tag-contact">Mobil:</span
            >{{ $store.state.customerprofile.Mobil }}<br />
            <span class="tag-contact">E-Mail:</span
            >{{ $store.state.customerprofile.Email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageFunctionsMixin from "@/mixins/imageFunctions.js";
import unterlagenKategorieCustomer from "@/components/unterlagenKategorieCustomer";
import unterlagenKategorieUeberschriftCustomer from "@/components/unterlagenKategorieUeberschriftCustomer";

import { firestore } from "@/plugins/firebase";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
  updateDoc,
} from "@firebase/firestore";

export default {
  name: "DBFrontend",
  mixins: [imageFunctionsMixin],
  components: {
    unterlagenKategorieCustomer,
    unterlagenKategorieUeberschriftCustomer,
  },
  data() {
    return {
      activeTab: 1,
      componentKey: 0,
      showGratulations: false,
    };
  },
  methods: {
    async setGratulationAsRead() {
      this.showGratulations = false;
      this.$store.state.customerprofile.auszahlungsinfoGesehen = true;

      let _collection = collection(firestore, "customerprofile/");
      let _doc = doc(_collection, this.$store.state.user.uid);

      let data = {
        auszahlungsinfoGesehen: true,
      };

      try {
        await updateDoc(_doc, data);
      } catch (err) {}
    },
    setInfoAsRead() {
      this.$store.state.customerprofile.auszahlungsinfoGesehen = true;
    },
    goBack() {
      this.activeTab = 1;
    },

    collapseAllItems() {
      for (
        let k = 0;
        k < this.$store.state.customerprofile.Kategorien.length;
        k++
      ) {
        const category = this.$store.state.customerprofile.Kategorien[k];
        for (let u = 0; u < category.ueberschriften.length; u++) {
          const ueberschrift = category.ueberschriften[u];
          for (let i = 0; i < ueberschrift.items.length; i++) {
            const item = ueberschrift.items[i];
            item.elapsed = false;
          }
        }
      }
      this.componentKey++;
    },
    kategoriesToShow() {
      let ret = [];
      if (this.$store.state.customerprofile) {
        for (
          let i = 0;
          i < this.$store.state.customerprofile.Kategorien.length;
          i++
        ) {
          const category = this.$store.state.customerprofile.Kategorien[i];
          if (this.headlinesToShow(category).length > 0) {
            ret.push(category);
          } else {
          }
        }
      }

      return ret;
    },
    headlinesToShow(category) {
      let ret = [];
      for (let i = 0; i < category.ueberschriften.length; i++) {
        const ueberschrift = category.ueberschriften[i];
        if (
          ueberschrift.items.filter((i) => i.toComplete === true).length > 0
        ) {
          ret.push(ueberschrift);
        }
      }
      return ret;
    },
    showKat(kat) {
      let x = kat.filter(
        (k) =>
          k.ueberschriften.filter((u) =>
            u.items.filter((i) => i.toComplete === true)
          ) > 0
      );
      console.log(x);
    },
    getKatByID(id) {
      let ret = this.$store.state.customerprofile.Kategorien.find(
        (kat) => kat.id == id
      );
      return ret;
    },
  },
  computed: {
    itemsToComplete() {
      let ret = {
        count: 0,
        erledigt: 0,
      };

      for (
        let i = 0;
        i < this.$store.state.customerprofile.Kategorien.length;
        i++
      ) {
        const kategorie = this.$store.state.customerprofile.Kategorien[i];
        for (let a = 0; a < kategorie.ueberschriften.length; a++) {
          const ueberschrift = kategorie.ueberschriften[a];
          for (let a = 0; a < ueberschrift.items.length; a++) {
            const item = ueberschrift.items[a];
            if (item.erledigt && item.erledigt === true) {
              ret.erledigt++;
            }
            if (item.toComplete && item.toComplete === true) {
              ret.count++;
            }
          }
        }
      }
      return ret;
    },
  },
  mounted() {
    if (!this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "Login" });
      return;
    } else if (this.$store.state.employeeprofile) {
      this.$router.push({ name: "DBBackend" });
      return;
    }

    if (
      (!this.$store.state.customerprofile.auszahlungsinfoGesehen ||
        this.$store.state.customerprofile.auszahlungsinfoGesehen === false) &&
      this.$store.state.customerprofile.AuszahlungAktiviert === true
    ) {
      this.showGratulations = true;
    }
  },
};
</script>