<template>
  <div>
    <header class="dashboard-header">
      <div class="header-headline">
        <h1 id="h1welcome">
          👋 Hallo {{ this.$store.state.employeeprofile.Vorname }}
        </h1>
        <div class="button-group">
          <a
            id="btn-mitarbeiterverwaltung"
            class="button-secondary"
            v-if="
              $store.state.employeeprofile.Rollen.includes(
                'MitarbeiterVerwalten'
              )
            "
            @click="gotoByName('EmployeesOverview')"
            >Mitarbeiterverwaltung
          </a>
          <a
            id="btn-kundeanlegen"
            class="button-primary"
            v-if="$store.state.employeeprofile.Rollen.includes('KundenAnlegen')"
            @click="gotoByName('NeuerKunde')"
            >Neuen Kunden anlegen</a
          >
        </div>
      </div>
    </header>

    <div v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div class="dashboard-content">
        <div class="grid-32">
          <div class="block-action">
            <div class="action-header">
              <h3>Kundenübersicht</h3>
            </div>
            <div>
              <v-switch
                v-model="showOnlyActive"
                :label="`Zeige nur Aktive Kunden`"
              ></v-switch>
            </div>
            <div class="action-list" :key="customersKey">
              <kundenUebersicht
                :kunde="k"
                v-for="k in filteredCustomers"
                :key="k.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import kundenUebersicht from "@/components/kundenUebersicht.vue";
import { firestore, functions } from "@/plugins/firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";

export default {
  name: "DashboardEmployee",
  mixins: [routingFunctionsMixin, firestoreFunctionsMixin],

  components: { kundenUebersicht },
  data() {
    return {
      showOnlyActive: true,
      loading: true,
      customersKey: 0,
      aavatars: 0,
    };
  },
  methods: {},
  watch: {
    
  },
  computed: {
    
    filteredCustomers() {
      let result = [];

      for (let customer of this.$store.state.myCustomers) {
        if (this.showOnlyActive === true) {
          if (customer.active === true) {
            result.push(customer);
          }
        } else {
          result.push(customer);
        }
      }
      this.customersKey++;
      return result;
    },
    Avatars(){
      
      return this.$store.state.avatars;
    }
  },
  async mounted() {
    this.loading = true;

    if (!this.$store.state.auth.isSignedIn) {
      this.$router.push({ name: "Login" });
      return;
    } else if (this.$store.state.customerprofile) {
      this.$router.push({ name: "DBFrontend" });
      return;
    }

    
    
    

    this.loading = false;
  },
};
</script>