import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"500","persistent":_vm.persistent},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('br'),_c(VCardText,[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.btnOk.color,"text":""},on:{"click":function($event){_vm.btnOk.fnc();
            _vm.visible = false;}}},[_vm._v(_vm._s(_vm.btnOk.text))]),_c(VBtn,{attrs:{"color":_vm.btnCancel.color,"text":""},on:{"click":function($event){_vm.btnCancel.fnc();
            _vm.visible = false;}}},[_vm._v(_vm._s(_vm.btnCancel.text))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }