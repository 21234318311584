<template>
  <div>
    <a
      class="action-item dashboard-admin"
      @click="$router.push('/customerprofile/' + kunde.uid)"
    >
      <div
        :class="[
          'item-title',
          AufgabenGesamt.count - AufgabenGesamt.erledigt == 0
            ? 'complete'
            : 'progress',
        ]"
      >
        <div class="title-customer">
          <h4>{{kunde.Anrede}} {{ kunde.Vorname }} {{ kunde.Nachname }}</h4>
        </div>

        <div class="title-vp">
          <div class="item-avatar">
            <img
            
              :src="getAvatarOrDummy(aktuellerVertriebspartner)"
              
            />
          </div>
          {{ aktuellerVertriebspartner.Vorname  }} {{ aktuellerVertriebspartner.Nachname }}
        </div>

        <div class="tag-group" >
          <div :class="[aufgabe.count == 0? '' :'tag', aufgabe.count == 0? '' : aufgabe.count - aufgabe.erledigt == 0 ? 'complete' : 'progress',]"
            v-for="aufgabe in Aufgaben"
            :key="aufgabe.title">
            <div v-if="aufgabe.count > 0">{{ aufgabe.title }}: {{ aufgabe.erledigt }}/{{ aufgabe.count }}</div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import routingFunctionsMixin from "@/mixins/routingFunctions.js";
import firestoreFunctionsMixin from "@/mixins/firestoreFunctions.js";
import imageFunctionsMixin from "@/mixins/imageFunctions.js";


export default {
  name: "KundenUebersicht",
  mixins: [routingFunctionsMixin, firestoreFunctionsMixin, imageFunctionsMixin],
  props: ["kunde"],
  data() {
    return {
      
      Aufgaben: {},
      AufgabenGesamt: {},
    };
  },
  methods: {
    getAufgabenstatusByKategorie(kategorie) {
      let ret = {
        count: 0,
        erledigt: 0,
      };
      for (let i = 0; i < kategorie.ueberschriften.length; i++) {
        const ueberschrift = kategorie.ueberschriften[i];
        for (let a = 0; a < ueberschrift.items.length; a++) {
          const item = ueberschrift.items[a];
          if (item.toComplete && item.toComplete === true) {
            if (item.erledigt) {
              ret.erledigt++;
            }
            ret.count++;
          }
        }
      }
      return ret;
    },
    getAufgaben() {
      let kategorien = this.kunde.Kategorien;

      let ret = [
        {
          count: 0,
          erledigt: 0,
          title: "Bonität",
        },
        {
          count: 0,
          erledigt: 0,
          title: "Objekt",
        },
        {
          count: 0,
          erledigt: 0,
          title: "Gesundheitsfragen",
        },
        {
          count: 0,
          erledigt: 0,
          title: "Auszahlung",
        },
      ];

      //BONITÄTSUNTERLAGEN
      let aufgabenstatusBonitaetsunterlagen = this.getAufgabenstatusByKategorie(
        kategorien.find((k) => k.id == 2)
      );
      ret[0].count = aufgabenstatusBonitaetsunterlagen.count;
      ret[0].erledigt = aufgabenstatusBonitaetsunterlagen.erledigt;

      //OBJEKTUNTERLAGEN
      let aufgabenstatusObjekt = this.getAufgabenstatusByKategorie(
        kategorien.find((k) => k.id == 3)
      );
      ret[1].count = aufgabenstatusObjekt.count;
      ret[1].erledigt = aufgabenstatusObjekt.erledigt;

      //GESUNDHEITSFRAGEN
      let aufgabenstatusGesundheitsfragen = this.getAufgabenstatusByKategorie(
        kategorien.find((k) => k.id == 4)
      );
      ret[2].count = aufgabenstatusGesundheitsfragen.count;
      ret[2].erledigt = aufgabenstatusGesundheitsfragen.erledigt;

      //AUSZAHLUNGSUNTERLAGEN
      let aufgabenstatusAuszahlungsunterlagen =
        this.getAufgabenstatusByKategorie(kategorien.find((k) => k.id == 5));
      ret[3].count = aufgabenstatusAuszahlungsunterlagen.count;
      ret[3].erledigt = aufgabenstatusAuszahlungsunterlagen.erledigt;
      return ret;
    },
    getAufgabenGesamt() {
      let ret = { count: 0, erledigt: 0 };
      let Aufgaben = this.getAufgaben();

      for (let i = 0; i < Aufgaben.length; i++) {
        const aufgabe = Aufgaben[i];
        ret.erledigt += aufgabe.erledigt;
        ret.count += aufgabe.count;
      }
      return ret;
    },
  },
  computed: {
    aktuellerVertriebspartner() {
      let vpId = this.kunde.Vertriebspartner;
      let ret = this.$store.state.allSalesPartners.find((vp) => vp.uid == vpId);
      if (ret) {
        return ret;
        
      }
      return "";
    },
  },
  async mounted() {
    

    this.Aufgaben = this.getAufgaben();
    this.AufgabenGesamt = this.getAufgabenGesamt();
  },
};
</script>